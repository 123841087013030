import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastPosition } from '../../constants/baseStyles';
import { emailValidate } from '../../constants/validation';
import { ErrorToast } from '../../services/toast/toast';
import {
  Page,
  LoginLeftContainer,
  LoginLeftVideo,
  LoginRightContainer,
  LoginFormContainer,
  LoginText,
  LoginInputBoxContainer,
  LoginInputBox,
  Image,
  LoginButtonContainer,
  LoginButton,
} from './Login.Styles';
import { Creators } from './store/action';
import eye from '../../assets/images/eye.png';
import eyeclosed from '../../assets/images/eyeclosed.png';

const Login = () => {
  const [passwordType, setpasswordType] = useState('password');
  const loginEmailRef = useRef();
  const loginPasswordRef = useRef();
  const loginButtonRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = useSelector((state) => state.auth.userType);
  const onLoginClick = (event) => {
    event.preventDefault();
    const validEmail = emailValidate(loginEmailRef.current?.value);
    const validPassword = emailValidate(loginPasswordRef.current?.value) || true;
    if (!validEmail) {
      ErrorToast('Enter valid email ', ToastPosition.TOP_CENTER, 'email-error');
      return false;
    } else if (!validPassword) {
      ErrorToast('Enter valid password', ToastPosition.TOP_CENTER, 'email-error');
      return false;
    }
    const payload = {
      email: loginEmailRef.current?.value.trim(),
      password: loginPasswordRef.current?.value.trim(),
    };
    dispatch(Creators.login(payload));
  };
  useEffect(() => {}, []);

  const onPasswordToggle = (event) => {
    if (passwordType === 'password') {
      setpasswordType('text');
    } else {
      setpasswordType('password');
    }
  };

  return (
    <Page>
      <LoginLeftContainer>
        <LoginLeftVideo
          id="myvideo"
          poster="https://odo.world/assets/images/Poster.png"
          src="https://odo.world/assets/Video/ODO_promo_video1.mp4"
          loop=""
          autoplay="true"
        ></LoginLeftVideo>
      </LoginLeftContainer>
      <LoginRightContainer>
        <LoginFormContainer>
          <LoginText>Login</LoginText>
          <LoginInputBoxContainer>
            <LoginInputBox id="email" type={'email'} placeholder="Email" ref={loginEmailRef} />
          </LoginInputBoxContainer>
          <LoginInputBoxContainer>
            <LoginInputBox
              id="password"
              type={passwordType}
              placeholder="Password"
              ref={loginPasswordRef}
            />
            {passwordType === 'password' ? (
              <Image src={eyeclosed} onClick={onPasswordToggle} />
            ) : (
              <Image src={eye} onClick={onPasswordToggle} />
            )}
          </LoginInputBoxContainer>
          <LoginButtonContainer>
            <LoginButton type="submit" onClick={onLoginClick} ref={loginButtonRef}>
              Login
            </LoginButton>
          </LoginButtonContainer>
        </LoginFormContainer>
      </LoginRightContainer>
    </Page>
  );
};

export default Login;
