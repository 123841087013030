import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMatch, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Page, Container } from './BioScope.Styles';
import { BioScopeCreators } from './store/action';

import {
  FromContainer,
  FormTopContainer,
  ImageContainer,
  ImagePreView,
  ImageInput,
  ImageContainerLabel,
  ImageContainerBottomLabel,
  FormMiddleContainer,
  GeneralInputContainer,
  GeneralInputLabel,
  GeneralInputBox,
  FormFooterContainer,
  SaveBioScopeButtonContainer,
  SaveBioScopeButton,
  CancelBioScopeButtonContainer,
  CancelBioScopeButton,
  HeaderContainer,
  HeaderRightContainer,
  EditBioScopeButtonContainer,
  EditBioScopeButton,
  GeneralDropdownContainer,
  SuggestionItem,
  ImageMessage,
} from './AddEditBioScope.Styles';
import { IMAGE_REGEX } from '../../constants/constant';
import { ErrorToast, WarningToast } from '../../services/toast/toast';
import { ToastPosition } from '../../constants/baseStyles';

const AddEditBioScope = () => {
  const dispatch = useDispatch();
  const selectedBioScopeObj = useSelector((state) => state.bioScope.selectedBioScopeObj);
  const AddBioScope = useMatch('bio-scope/add');
  const navigate = useNavigate();
  const location = useLocation();
  const ApproveBioScope = location.pathname.includes('bio-scope/approval/');

  const [bioScope, setBioScope] = useState(selectedBioScopeObj);
  const [bioScopeEdited, setBioScopeEdited] = useState(false);
  const [editClicked, setEditClicked] = useState(AddBioScope !== null);
  // eslint-disable-next-line no-unused-vars
  const [bioScopeApproval, setBioScopeApproval] = useState(ApproveBioScope);
  const fileInputArray = [];

  const suggestionList = useSelector((state) => state.bioScope.suggestions);
  const loadingAdd = useSelector((state) => state.bioScope.loadingAddBioScope);
  const [showSuggList, setShowSuggList] = useState(false);
  const [ansInvalid, setAnsInvalid] = useState(true);

  const loadingApprove = useSelector((state) => state.bioScope.loadingApproveBioScope);
  const [approveClick, setApproveClick] = useState(false);

  useEffect(() => {
    if (AddBioScope === null) {
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      dispatch(BioScopeCreators.bioScopeDetails({ bioScopeId: id }));
    } else {
      dispatch(BioScopeCreators.addBioScopeSuccess({}));
    }
  }, []);

  useEffect(() => {
    setBioScope(selectedBioScopeObj);
  }, [selectedBioScopeObj]);

  const onImageUploadClick = (fileId) => {
    //
    fileInputArray[fileId].click();
  };

  const onFieldsValueChange = (fieldName, value, id = '', index = 0) => {
    const newSelectedBioScope = { ...bioScope };
    if (fieldName === 'FILE') {
      //
      if (IMAGE_REGEX.test(value?.target?.files[0]?.name)) {
        //
        const objectUrl = URL.createObjectURL(value?.target?.files[0]);
        newSelectedBioScope.files[index].file = value?.target?.files[0];
        newSelectedBioScope.files[index].imageURL = objectUrl;
        setBioScope(newSelectedBioScope);
      } else {
        ErrorToast('Select Image', ToastPosition.TOP_CENTER, `ERROR${index}`);
      }
    } else if (fieldName === 'startDate') {
      newSelectedBioScope[fieldName] = value.target.value;
      setBioScope(newSelectedBioScope);
    } else if (fieldName === 'endDate') {
      newSelectedBioScope[fieldName] = value.target.value;
      setBioScope(newSelectedBioScope);
    } else {
      newSelectedBioScope[fieldName] = value.target.value;
      setBioScope(newSelectedBioScope);
      setAnsInvalid(true);
      if (value.target.value.length > 0) {
        setShowSuggList(true);
        dispatch(BioScopeCreators.bioscopeMovieSuggestion({ name: value.target.value }));
      } else {
        setShowSuggList(false);
      }
    }
    if (!bioScopeEdited) {
      setBioScopeEdited(true);
    }
  };

  const onCancelClick = () => {
    if (AddBioScope) {
      navigate('/bio-scope');
    } else {
      setEditClicked(false);
      setShowSuggList(false);
      setBioScope(selectedBioScopeObj);
    }
  };

  const onSaveClick = () => {
    //
    const { files, rightAnswerOtherLang, rightAnswerEnglish, startDate, endDate, answerId } =
      bioScope;
    // edit mode
    if (AddBioScope === null) {
      let dataEdited = false;
      const filesUpdated = files.filter((file) => file.file);
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      if (ansInvalid) {
        WarningToast('Choose Movie from dropdown', ToastPosition.TOP_CENTER, 'Ans-Invalid');
        return;
      }
      if (filesUpdated) {
        //
        dataEdited = true;
      }
      dataEdited = dataEdited || answerId !== selectedBioScopeObj.answerId;
      dataEdited = dataEdited || startDate !== selectedBioScopeObj.startDate;
      dataEdited = dataEdited || endDate !== selectedBioScopeObj.endDate;
      if (!dataEdited) {
        WarningToast('Data is not edited', ToastPosition.TOP_CENTER, 'data-edit-failed');
      } else {
        const fileUpload = new FormData();
        if (filesUpdated) {
          //
          filesUpdated.forEach((file) => {
            let fileKeys = ['f_one', 'f_two', 'f_three', 'f_four', 'f_five'];
            fileUpload.append(fileKeys[file.index], file.file);
          });
        }
        if (rightAnswerEnglish !== selectedBioScopeObj.rightAnswerEnglish) {
          //
          fileUpload.append('answer', answerId);
        }
        if (startDate !== selectedBioScopeObj.startDate) {
          //
          fileUpload.append('start_datetime', startDate.trim());
        }
        if (endDate !== selectedBioScopeObj.endDate) {
          //
          fileUpload.append('end_datetime', endDate.trim());
        }
        const payload = {
          fileUpload,
          bioScopeId: id,
        };
        dispatch(BioScopeCreators.editBioScope(payload));
      }
    } else {
      // for add mode
      let dataMissed = true;
      const filesUpdated = files.filter((file) => file.file === null);
      if (filesUpdated.length === 0) {
        //
        dataMissed = false;
      }
      if (dataMissed) {
        WarningToast('Select All images for Bio Score', ToastPosition.TOP_CENTER, 'Add-image');
        return;
      }
      dataMissed = rightAnswerOtherLang.trim() === '';
      if (dataMissed) {
        WarningToast('Other Language Empty!', ToastPosition.TOP_CENTER, 'Add-other-lan');
        return;
      }
      dataMissed = rightAnswerEnglish.trim() === '';
      if (dataMissed) {
        WarningToast('Invalid Right Answer English!', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed = startDate.trim() === '';
      if (dataMissed) {
        WarningToast('Select Start date & time', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      dataMissed = endDate.trim() === '';
      if (dataMissed) {
        WarningToast('Select End date & time', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      if (ansInvalid) {
        WarningToast('Choose Movie from dropdown', ToastPosition.TOP_CENTER, 'Ans-Invalid');
        return;
      }
      const sDate = moment(startDate).utc().format();
      const eDate = moment(endDate).utc().format();
      if (moment(endDate).isBefore(moment(startDate))) {
        WarningToast(
          'Bio Scope is ending before start',
          ToastPosition.TOP_CENTER,
          'INCORRECT-DATE'
        );
        return;
      }
      const fileUpload = new FormData();
      fileUpload.append('f_one', files[0].file);
      fileUpload.append('f_two', files[1].file);
      fileUpload.append('f_three', files[2].file);
      fileUpload.append('f_four', files[3].file);
      fileUpload.append('f_five', files[4].file);
      fileUpload.append('answer', answerId);
      fileUpload.append('start_datetime', sDate);
      fileUpload.append('end_datetime', eDate);
      const payload = {
        fileUpload,
      };
      dispatch(BioScopeCreators.addBioScope(payload));
    }
    //
  };

  const onApproveBioScopeClick = () => {
    //
    setApproveClick(true);
    const index = location.pathname.lastIndexOf('/');
    const id = location.pathname.slice(index + 1);
    const payload = {
      bioScopeId: id,
      requestType: 'APPROVE',
    };
    dispatch(BioScopeCreators.approveRejectBioScope(payload));
  };

  const onRejectBioScopeClick = () => {
    //
    setApproveClick(false);
    const index = location.pathname.lastIndexOf('/');
    const id = location.pathname.slice(index + 1);
    const payload = {
      bioScopeId: id,
      requestType: 'REJECT',
    };
    dispatch(BioScopeCreators.approveRejectBioScope(payload));
  };

  const suggestionSelected = (fieldName, fieldName2, suggestion) => {
    setShowSuggList(false);
    const newSelectedBioScope = { ...bioScope };
    newSelectedBioScope[fieldName] = suggestion.name;
    newSelectedBioScope[fieldName2] = suggestion.local_name;
    newSelectedBioScope.answerId = suggestion.id;
    setBioScope(newSelectedBioScope);
    setAnsInvalid(false);
  };

  const editClickHandler = (editFor, payload) => {
    if (!editClicked) {
      WarningToast('Enable Edit Mode.', ToastPosition.TOP_CENTER, 'data-edit-failed');
    } else {
      if (editFor === 'image') {
        onImageUploadClick(payload);
      } else if (editFor === 'rightAnswerOtherLang') {
        onFieldsValueChange('rightAnswerOtherLang', payload);
      } else if (editFor === 'rightAnswerEnglish') {
        onFieldsValueChange('rightAnswerEnglish', payload);
      } else if (editFor === 'startDate') {
        onFieldsValueChange('startDate', payload);
      } else if (editFor === 'endDate') {
        onFieldsValueChange('endDate', payload);
      }
    }
  };

  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderRightContainer>
            {!bioScopeApproval && AddBioScope === null && !editClicked && (
              <EditBioScopeButtonContainer>
                <EditBioScopeButton
                  onClick={() => {
                    setEditClicked(true);
                  }}
                >
                  Edit Bio Scope
                </EditBioScopeButton>
              </EditBioScopeButtonContainer>
            )}
          </HeaderRightContainer>
        </HeaderContainer>
        <FromContainer>
          <FormTopContainer>
            {bioScope.files.map((file, index) => {
              return (
                <ImageContainer key={file.id} onClick={() => editClickHandler('image', index)}>
                  <ImagePreView src={file.imageURL !== '' ? file.imageURL : ''} />
                  <ImageMessage show={!bioScopeApproval && AddBioScope === null && editClicked}>
                    Click to edit image.
                  </ImageMessage>
                  <ImageInput
                    type={'file'}
                    onChange={(file) => onFieldsValueChange('FILE', file, file.id, index)}
                    ref={(ref) => (fileInputArray[index] = ref)}
                  />
                  <ImageContainerLabel>
                    {file.imageURL !== '' ? file.label : 'Click to add an image.'}
                  </ImageContainerLabel>
                  <ImageContainerBottomLabel>{file.label}</ImageContainerBottomLabel>
                </ImageContainer>
              );
            })}
          </FormTopContainer>
          <FormMiddleContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>Right Answer Original Language</GeneralInputLabel>
              <GeneralInputBox
                value={bioScope.rightAnswerOtherLang}
                onChange={(event) => editClickHandler('rightAnswerOtherLang', event)}
              />
            </GeneralInputContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>Right Answer English</GeneralInputLabel>
              <GeneralInputBox
                value={bioScope.rightAnswerEnglish}
                onChange={(event) => editClickHandler('rightAnswerEnglish', event)}
              />
              <GeneralDropdownContainer
                show={
                  showSuggList &&
                  suggestionList.length > 0 &&
                  bioScope.rightAnswerEnglish.length > 0
                }
              >
                {suggestionList.map((suggestion, index) => {
                  return (
                    <SuggestionItem
                      key={index}
                      onClick={() =>
                        suggestionSelected('rightAnswerEnglish', 'rightAnswerOtherLang', suggestion)
                      }
                    >
                      {suggestion.name}
                    </SuggestionItem>
                  );
                })}
              </GeneralDropdownContainer>
            </GeneralInputContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>Bio Scope Start Date & Time</GeneralInputLabel>
              <GeneralInputBox
                type={'datetime-local'}
                value={bioScope.startDate}
                onChange={(event) => editClickHandler('startDate', event)}
              />
            </GeneralInputContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>Bio Scope End Date & Time</GeneralInputLabel>
              <GeneralInputBox
                type={'datetime-local'}
                value={bioScope.endDate}
                onChange={(event) => editClickHandler('endDate', event)}
              />
            </GeneralInputContainer>
          </FormMiddleContainer>
          {!bioScopeApproval && editClicked && (
            <FormFooterContainer>
              <SaveBioScopeButtonContainer>
                <SaveBioScopeButton
                  onClick={loadingAdd ? () => {} : onSaveClick}
                  loadingAdd={loadingAdd}
                  cursorPointer={!(loadingApprove && approveClick)}
                >
                  {loadingAdd ? 'Adding...' : 'Save'}
                </SaveBioScopeButton>
              </SaveBioScopeButtonContainer>
              <CancelBioScopeButtonContainer>
                <CancelBioScopeButton onClick={onCancelClick}>Cancel</CancelBioScopeButton>
              </CancelBioScopeButtonContainer>
            </FormFooterContainer>
          )}
          {bioScopeApproval && (
            <FormFooterContainer>
              <SaveBioScopeButtonContainer>
                <SaveBioScopeButton
                  onClick={loadingApprove && approveClick ? () => {} : onApproveBioScopeClick}
                  cursorPointer={!(loadingApprove && approveClick)}
                >
                  {loadingApprove && approveClick ? 'Approving...' : 'Approve'}
                </SaveBioScopeButton>
              </SaveBioScopeButtonContainer>
              <CancelBioScopeButtonContainer>
                <CancelBioScopeButton
                  onClick={loadingApprove && !approveClick ? () => {} : onRejectBioScopeClick}
                >
                  {loadingApprove && !approveClick ? 'Rejecting...' : 'Reject'}
                </CancelBioScopeButton>
              </CancelBioScopeButtonContainer>
            </FormFooterContainer>
          )}
        </FromContainer>
      </Container>
    </Page>
  );
};

export default AddEditBioScope;
