import styled from 'styled-components';
import {
  backgroundColor,
  backgroundSecondayColor,
  borderSecondayColor,
} from '../../constants/baseStyles';

const Page = styled.div`
  display: flex;
  background-color: ${backgroundSecondayColor};
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 58px);
`;

const Container = styled.div`
  margin: 5px 5px 0 5px;
  width: 99%;
  min-height: calc(100vh - 58px);
  border-top: 3px solid ${borderSecondayColor};
  border-radius: 5px;
  background-color: ${backgroundColor};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
`;

export { Page, Container };
