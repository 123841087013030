import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import NavigationRoutes from './routes/NavigationRoutes';
import { cookie } from './services/cookies/cookieServices';
// import { Creators as LoginCreators } from './store/actions';

import 'react-toastify/dist/ReactToastify.css';
import { Creators as AuthCreators } from './pages/Auth/store/action';
import { setAccessTokenToHeader } from './services/axios/helper';

const App = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.userType);
  const userDetails = cookie.get('userDetails');
  useEffect(() => {
    //
    if (userDetails) {
      const { accessToken, userType } = userDetails;
      if (accessToken && userType) {
        const payload = {
          accessToken,
          userType,
        };
        // this.props.userMetaData(payload);

        setAccessTokenToHeader(accessToken);
        dispatch(AuthCreators.loginSuccess(payload));
      }
    }
  }, [userDetails]);

  return (
    <div>
      <NavigationRoutes />
      <ToastContainer />
    </div>
  );
};

export default App;
