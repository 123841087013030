import { combineReducers } from 'redux';
import { authReducer } from '../pages/Auth/store/reducer';
import { bioScopeReducer } from '../pages/BioScope/store/reducer';
import { userDataReducer } from '../pages/Users/store/reducer';
import { campaignReducer } from '../pages/Campaigns/store/reducer';
import { notificationReducer } from '../pages/Notification/store/reducer';
import { companyReducer } from '../pages/Company/store/reducer';
import { couponInstanceReducer } from '../pages/CouponInstance/store/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  bioScope: bioScopeReducer,
  userData: userDataReducer,
  campaignData: campaignReducer,
  notification: notificationReducer,
  company: companyReducer,
  couponInstance: couponInstanceReducer,
});

export default rootReducer;
