import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  backgroundThirdColor,
  sidebarHighlight,
  borderSecondayColor,
  buttonTextPrimaryColor,
  backgroundSecondayColor,
  backgroundColor,
  boxShadowStyle,
  buttonBackgroundPrimaryColor,
  textSecondayColor,
  LATO,
  OPEN_SANS,
} from '../../constants/baseStyles';
const Paginationcontainer = styled.div`
  display: flex;
  position: absolute;
  align-self: center;
  bottom: 5%;
  left: 50%;
  align-self: center;
  cursor: pointer;
`;
const PaginationNumber = styled.div`
  display: flex;
  background-color: ${(props) => (props.set === true ? buttonBackgroundPrimaryColor : 'white')};
  color: ${(props) => (props.set === true ? 'white' : 'black')};
  margin: 2px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${borderSecondayColor};
  border-radius: 4px;
`;
export { Paginationcontainer, PaginationNumber };
