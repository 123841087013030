import { Types as AuthTypes, Creators as AuthCreators } from '../pages/Auth/store/action';
import { BioScopeTypes, BioScopeCreators } from '../pages/BioScope/store/action';
import { userDataTypes, userDataCreators } from '../pages/Users/store/action';
import { CampaignsTypes, CampaignsCreators } from '../pages/Campaigns/store/action';
import { NotificationTypes, NotificationCreators } from '../pages/Notification/store/action';
import { CompanyTypes, CompanyCreators } from '../pages/Company/store/action';
import { CouponInstanceTypes, CouponInstanceCreators } from '../pages/CouponInstance/store/action';

const Types = {
  ...AuthTypes,
  ...BioScopeTypes,
  ...userDataTypes,
  ...CampaignsTypes,
  ...NotificationTypes,
  ...CompanyTypes,
  ...CouponInstanceTypes,
};

const Creators = {
  ...AuthCreators,
  ...BioScopeCreators,
  ...userDataCreators,
  ...CampaignsCreators,
  ...NotificationCreators,
  ...CompanyCreators,
  ...CouponInstanceCreators,
};

export { Types, Creators };
