import styled from 'styled-components';
import {
  borderSecondayColor,
  OPEN_SANS,
  LATO,
  titleTextColor,
  backgroundColor,
  textPrimaryColor,
  backgroundSecondayColor,
  buttonTextPrimaryColor,
  buttonBackgroundPrimaryColor,
  borderPrimaryColor,
  boxShadowStyle,
} from '../../constants/baseStyles';

const Page = styled.div`
  background-color: ${backgroundSecondayColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NotificationsContainer = styled.div`
  margin: 5px 5px 0 5px;
  width: 99%;
  height: calc(100vh - 58px);
  border-top: 3px solid ${borderSecondayColor};
  border-radius: 5px;
  background-color: ${backgroundColor};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
`;
const NotificationTop = styled.div`
  margin: 10px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
`;
const NotificationHeading = styled.h3`
  font-size: 24px;
  color: ${titleTextColor};
  font-weight: 400;
  font-family: ${LATO};
  lint-height: 1.1;
  margin-top: 20px;
`;
const NotificationButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: ${buttonTextPrimaryColor};
  background-color: ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  padding: 6px 12px;
  font-family: ${OPEN_SANS};
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  text-decoration: none;
  font-size: 1rem;
  height: 30px;
`;

const NotificationTableContainer = styled.div`
  display: flex;
  font-family: ${OPEN_SANS};
  border-collapse: collapse;
  margin-top: 20px;
  height: 70vh;
`;

const NotificationInnerTableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const NotificationTable = styled.table`
  width: 95%;
  margin-left: 30px;
  margin-right: 30px;
  padding: 20px;
`;
const NotificationTableHead = styled.thead``;
const NotificationTableBody = styled.tbody``;
const NotificationTableLoadingData = styled.td`
  width: 100%;
  text-align: center;
  margin-top: 75px;
`;
const NotificationTableHeading = styled.th`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: ${textPrimaryColor};
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid ${borderPrimaryColor};
  font-family: ${OPEN_SANS};
`;
const NotificationTableRow = styled.tr`
  display: flex;
  width: 100%;
`;
const NotificationTableData = styled.td`
  display: flex;
  flex-direction: row;
  color: ${textPrimaryColor};
  border-bottom: 1px solid ${borderPrimaryColor};
  font-family: ${OPEN_SANS};
  width: 100%;
  padding: 10px 20px 10px 0;
`;

export {
  Page,
  NotificationsContainer,
  NotificationHeading,
  NotificationTop,
  NotificationButton,
  NotificationTableContainer,
  NotificationInnerTableContainer,
  NotificationTable,
  NotificationTableHeading,
  NotificationTableRow,
  NotificationTableData,
  NotificationTableHead,
  NotificationTableBody,
  NotificationTableLoadingData,
};
