import { PlusIcon } from '../../constants/globalStyles.Styles';
import Pagination from '../Pagination/Pagination.View';
import moment from 'moment';
import {
  Page,
  Container,
  HeaderContainer,
  HeaderLeftContainer,
  HeaderTitleContainer,
  HeaderTitle,
  HeaderRightContainer,
  AddButtonContainer,
  AddButton,
  TableContainer,
  Table,
  Thead,
  TH,
  TBoddy,
  TR,
  TD,
  ApprovedColumn,
  LoadingFiller,
} from './Table.Styles';

const CommonTable = ({
  pageTitle = '',
  addButtonText = '',
  tableHeader = [],
  tableData = [],
  totalPage = 0,
  loading = false,
  pageSize = 10,
  onChangePageClick = null,
  visitDetails = null,
  addButtonNavigation = '',
}) => {
  // const navigate = useNavigate();

  // const visitDetails = (e) => {
  //   navigate(`details/${e}`);
  // };

  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderLeftContainer>
            <HeaderTitleContainer>
              <HeaderTitle>{pageTitle}</HeaderTitle>
            </HeaderTitleContainer>
          </HeaderLeftContainer>
          <HeaderRightContainer>
            <AddButtonContainer>
              <AddButton to={addButtonNavigation}>
                <PlusIcon />
                {addButtonText}
              </AddButton>
            </AddButtonContainer>
          </HeaderRightContainer>
        </HeaderContainer>
        <TableContainer>
          <Table>
            <Thead>
              <TR>
                {tableHeader.map((header, index) => {
                  return <TH key={`${index}`}>{header.title}</TH>;
                })}
              </TR>
            </Thead>
            <TBoddy>
              {loading ? (
                <TR>
                  <TD>
                    <LoadingFiller>Fetching Data...</LoadingFiller>
                  </TD>
                </TR>
              ) : (
                tableData.map((row) => {
                  return (
                    <TR
                      key={`${row.id}${Math.random()}`}
                      onClick={() => {
                        if (visitDetails) {
                          visitDetails(row.id);
                        }
                      }}
                    >
                      {tableHeader.map((headerKey, index) => {
                        if (headerKey.dataConvert === 'Date') {
                          return (
                            <TD key={`${index}${Math.random()}`}>
                              {moment(row[headerKey.key]).format('DD MMM YY,  hh:mm A')}
                            </TD>
                          );
                        }
                        return <TD key={`${index}${Math.random()}`}>{row[headerKey.key]}</TD>;
                      })}
                    </TR>
                  );
                })
              )}
            </TBoddy>
          </Table>
          <Pagination
            total_pages={totalPage}
            pagesFor={'CommonTable'}
            page_size={pageSize}
            onChangePageClick={onChangePageClick}
          ></Pagination>
        </TableContainer>
      </Container>
    </Page>
  );
};
export default CommonTable;
