import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';
import { Page, Container } from './Campaign.Styles';
import { CampaignsCreators } from './store/action';

import {
  FromContainer,
  FormTopContainer,
  FormMiddleContainer,
  GeneralInputContainer,
  GeneralInputLabel,
  GeneralInputBox,
  FormFooterContainer,
  SaveBioScopeButtonContainer,
  SaveBioScopeButton,
  CancelBioScopeButtonContainer,
  CancelBioScopeButton,
  HeaderContainer,
  HeaderRightContainer,
  EditBioScopeButtonContainer,
  EditBioScopeButton,
  GeneralTextAreaBox,
} from './AddEditDetailsCampaign.Styles';
import { WarningToast } from '../../services/toast/toast';
import { ToastPosition } from '../../constants/baseStyles';

const AddEditDetailsCampaign = () => {
  const dispatch = useDispatch();
  const campaignDetailsObj = useSelector((state) => state.campaignData.campaignDetails);
  const AddBioScopeCamapign = useMatch('bio-scope-campaign/add');
  const navigate = useNavigate();
  const location = useLocation();
  const ApproveBioScope = location.pathname.includes('bio-scope/approval/');

  const [campaignDetails, setCampaignDetails] = useState(campaignDetailsObj);
  const [bioScopeEdited, setBioScopeEdited] = useState(false);
  const [editClicked, setEditClicked] = useState(AddBioScopeCamapign !== null);
  const [bioScopeCampaignApproval, setbioScopeCampaignApproval] = useState(ApproveBioScope);

  const loadingAdd = useSelector((state) => state.bioScope.loadingAddBioScope);

  const loadingApprove = useSelector((state) => state.bioScope.loadingApproveBioScope);
  const [approveClick, setApproveClick] = useState(false);

  useEffect(() => {
    if (AddBioScopeCamapign === null) {
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      //   dispatch(CampaignsCreators.bioScopeDetails({ bioScopeId: id }));
    } else {
      dispatch(CampaignsCreators.addCampaignSuccess({}));
    }
  }, []);

  useEffect(() => {
    setCampaignDetails(campaignDetailsObj);
  }, [campaignDetailsObj]);

  const onFieldsValueChange = (fieldName, value, id = '', index = 0) => {
    const newcampaignDetails = { ...campaignDetails };
    if (fieldName === 'startDate') {
      newcampaignDetails[fieldName] = value.target.value;
      setCampaignDetails(newcampaignDetails);
    } else if (fieldName === 'endDate') {
      newcampaignDetails[fieldName] = value.target.value;
      setCampaignDetails(newcampaignDetails);
    } else {
      newcampaignDetails[fieldName] = value.target.value;
      setCampaignDetails(newcampaignDetails);
    }
    if (!bioScopeEdited) {
      setBioScopeEdited(true);
    }
  };

  const onCancelClick = () => {
    if (AddBioScopeCamapign) {
      navigate('/bio-scope-camapign ');
    } else {
      setEditClicked(false);
      setCampaignDetails(campaignDetailsObj);
    }
  };

  const onSaveClick = () => {
    //
    const { localName, name, startDate, endDate } = campaignDetails;
    // edit mode
    if (AddBioScopeCamapign === null) {
      let dataEdited = false;
      //   const index = location.pathname.lastIndexOf('/');
      //   const id = location.pathname.slice(index + 1);

      dataEdited = dataEdited || startDate !== campaignDetailsObj.startDate;
      dataEdited = dataEdited || endDate !== campaignDetailsObj.endDate;
      if (!dataEdited) {
        WarningToast('Data is not edited', ToastPosition.TOP_CENTER, 'data-edit-failed');
      } else {
        // const payload = {};
        // dispatch(CampaignsCreators.editBioScope(payload));
      }
    } else {
      // for add mode
      let dataMissed = true;
      dataMissed = localName.trim() === '';
      if (dataMissed) {
        WarningToast(
          'BioScope Campaign Text in Regional Langauage is empty!',
          ToastPosition.TOP_CENTER,
          'Add-other-lan'
        );
        return;
      }
      dataMissed = name.trim() === '';
      if (dataMissed) {
        WarningToast(
          'BioScope Campaign Text in English is empty!',
          ToastPosition.TOP_CENTER,
          'Add-english'
        );
        return;
      }
      dataMissed = startDate.trim() === '';
      if (dataMissed) {
        WarningToast('Select Start date & time', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      dataMissed = endDate.trim() === '';
      if (dataMissed) {
        WarningToast('Select End date & time', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      const sDate = moment(startDate).utc().format();
      const eDate = moment(endDate).utc().format();
      if (moment(endDate).isBefore(moment(startDate))) {
        WarningToast(
          'Bio Scope Campaign is ending before start',
          ToastPosition.TOP_CENTER,
          'INCORRECT-DATE'
        );
        return;
      }
      const payload = {
        createCampaingData: {
          name,
          local_name: localName,
          start_datetime: sDate,
          end_datetime: eDate,
        },
      };
      dispatch(CampaignsCreators.addCampaign(payload));
    }
    //
  };

  const onApproveBioScopeClick = () => {
    //
    // setApproveClick(true);
    // const index = location.pathname.lastIndexOf('/');
    // const id = location.pathname.slice(index + 1);
    // const payload = {
    //   bioScopeCampaignId: id,
    //   requestType: 'APPROVE',
    // };
    // dispatch(CampaignsCreators.approveRejectBioScope(payload));
  };

  const onRejectBioScopeClick = () => {
    //
    // setApproveClick(false);
    // const index = location.pathname.lastIndexOf('/');
    // const id = location.pathname.slice(index + 1);
    // const payload = {
    //   bioScopeCampaignId: id,
    //   requestType: 'REJECT',
    // };
    // dispatch(CampaignsCreators.approveRejectBioScope(payload));
  };

  const editClickHandler = (editFor, payload) => {
    if (!editClicked) {
      WarningToast('Enable Edit Mode.', ToastPosition.TOP_CENTER, 'data-edit-failed');
    } else {
      if (editFor === 'localName') {
        onFieldsValueChange('localName', payload);
      } else if (editFor === 'name') {
        onFieldsValueChange('name', payload);
      } else if (editFor === 'startDate') {
        onFieldsValueChange('startDate', payload);
      } else if (editFor === 'endDate') {
        onFieldsValueChange('endDate', payload);
      }
    }
  };

  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderRightContainer>
            {!bioScopeCampaignApproval && AddBioScopeCamapign === null && !editClicked && (
              <EditBioScopeButtonContainer>
                <EditBioScopeButton
                  onClick={() => {
                    setEditClicked(true);
                  }}
                >
                  Edit Bio Scope
                </EditBioScopeButton>
              </EditBioScopeButtonContainer>
            )}
          </HeaderRightContainer>
        </HeaderContainer>
        <FromContainer>
          <FormTopContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>BioScope Campaign Text in Regional Langauage</GeneralInputLabel>
              <GeneralTextAreaBox
                value={campaignDetails.localName}
                onChange={(event) => editClickHandler('localName', event)}
              />
            </GeneralInputContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>BioScope Campaign Text in English</GeneralInputLabel>
              <GeneralTextAreaBox
                value={campaignDetails.name}
                onChange={(event) => editClickHandler('name', event)}
              />
            </GeneralInputContainer>
          </FormTopContainer>
          <FormMiddleContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>BioScope Campaign Start Date & Time</GeneralInputLabel>
              <GeneralInputBox
                type={'datetime-local'}
                value={campaignDetails.startDate}
                onChange={(event) => editClickHandler('startDate', event)}
              />
            </GeneralInputContainer>
            <GeneralInputContainer>
              <GeneralInputLabel>BioScope Campaign End Date & Time</GeneralInputLabel>
              <GeneralInputBox
                type={'datetime-local'}
                value={campaignDetails.endDate}
                onChange={(event) => editClickHandler('endDate', event)}
              />
            </GeneralInputContainer>
          </FormMiddleContainer>
          {!bioScopeCampaignApproval && editClicked && (
            <FormFooterContainer>
              <SaveBioScopeButtonContainer>
                <SaveBioScopeButton
                  onClick={loadingAdd ? () => {} : onSaveClick}
                  loadingAdd={loadingAdd}
                  cursorPointer={!(loadingApprove && approveClick)}
                >
                  {loadingAdd ? 'Adding...' : 'Save'}
                </SaveBioScopeButton>
              </SaveBioScopeButtonContainer>
              <CancelBioScopeButtonContainer>
                <CancelBioScopeButton onClick={onCancelClick}>Cancel</CancelBioScopeButton>
              </CancelBioScopeButtonContainer>
            </FormFooterContainer>
          )}
          {bioScopeCampaignApproval && (
            <FormFooterContainer>
              <SaveBioScopeButtonContainer>
                <SaveBioScopeButton
                  onClick={loadingApprove && approveClick ? () => {} : onApproveBioScopeClick}
                  cursorPointer={!(loadingApprove && approveClick)}
                >
                  {loadingApprove && approveClick ? 'Approving...' : 'Approve'}
                </SaveBioScopeButton>
              </SaveBioScopeButtonContainer>
              <CancelBioScopeButtonContainer>
                <CancelBioScopeButton
                  onClick={loadingApprove && !approveClick ? () => {} : onRejectBioScopeClick}
                >
                  {loadingApprove && !approveClick ? 'Rejecting...' : 'Reject'}
                </CancelBioScopeButton>
              </CancelBioScopeButtonContainer>
            </FormFooterContainer>
          )}
        </FromContainer>
      </Container>
    </Page>
  );
};

export default AddEditDetailsCampaign;
