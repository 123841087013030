import { toast } from 'react-toastify';

const configuration = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const Toast = (message, position, toastId) => {
  const toastConfiguration = {
    ...configuration,
    position: position ? position : configuration.position,
    toastId,
  };

  return toast(message, toastConfiguration);
};

export const WarningToast = (message, position, toastId) => {
  const toastConfiguration = {
    ...configuration,
    position: position ? position : configuration.position,
    toastId,
  };

  return toast.warn(message, toastConfiguration);
};

export const ErrorToast = (message, position, toastId) => {
  const toastConfiguration = {
    ...configuration,
    position: position ? position : configuration.position,
    toastId,
  };

  return toast.error(message, toastConfiguration);
};

export const SuccessToast = (message, position, toastId) => {
  const toastConfiguration = {
    ...configuration,
    position: position ? position : configuration.position,
    toastId,
  };

  return toast.success(message, toastConfiguration);
};

export const DismissToast = (toastRef) => {
  if (toastRef) {
    toast.dismiss(toastRef);
  } else {
    toast.dismiss();
  }
};

/**
 * Example
 * const ToastRef = React.createRef();
 * this.ToastRef.current = Toast('Hello', 'top-center');
 * To dismiss specific toast programmatically : DismissToast(this.ToastRef.current)
 * To dismiss all toast : DismissToast()
 */
