import { createReducer } from 'reduxsauce';
import { userDataTypes } from './action';
const INITIAL_STATE = {
  loading: false,
  userData: [],
  total_pages: 0,
  loadUserMessage: '',
  addUserMessage: '',
  deleteUserMessage: '',
  deactivateUserMessage: '',
  activateUserMessage: '',
};

const loadUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const loadUserDataSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { users, total_pages },
  } = action;

  return {
    ...state,
    loading: false,
    userData: users,
    total_pages: total_pages,
    loadUserMessage: 'succeed',
  };
};
const loadUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    loadUserMessage: 'Failed',
  };
};
const addUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const addUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    addUserMessage: 'Succeed',
  };
};
const addUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    addUserMessage: 'Failed',
  };
};

const deleteUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const deleteUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    deleteUserMessage: 'Succeed',
  };
};
const deleteUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    deleteUserMessage: 'Failed',
  };
};

const deactivateUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const deactivateUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    deactivateUserMessage: 'Succeed',
  };
};
const deactivateUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    deactivateUserMessage: 'Failed',
  };
};

const activateUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const activateUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    activateUserMessage: 'Succeed',
  };
};
const activateUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    activateUserMessage: 'Failed',
  };
};

const dataReset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserMessage: '',
    loadUserMessage: '',
    deleteUserMessage: '',
    deactivateUserMessage: '',
    activateUserMessage: '',
  };
};

const HANDLERS = {
  [userDataTypes.LOAD_USER_DATA_START]: loadUserDataStart,
  [userDataTypes.LOAD_USER_DATA_SUCCESS]: loadUserDataSuccess,
  [userDataTypes.LOAD_USER_DATA_FAILURE]: loadUserDataFailure,
  [userDataTypes.ADD_USER_DATA_START]: addUserDataStart,
  [userDataTypes.ADD_USER_DATA_SUCCESS]: addUserDataSuccess,
  [userDataTypes.ADD_USER_DATA_FAILURE]: addUserDataFailure,
  [userDataTypes.DELETE_USER_DATA_START]: deleteUserDataStart,
  [userDataTypes.DELETE_USER_DATA_SUCCESS]: deleteUserDataSuccess,
  [userDataTypes.DELETE_USER_DATA_FAILURE]: deleteUserDataFailure,
  [userDataTypes.DEACTIVATE_USER_DATA_START]: deactivateUserDataStart,
  [userDataTypes.DEACTIVATE_USER_DATA_SUCCESS]: deactivateUserDataSuccess,
  [userDataTypes.DEACTIVATE_USER_DATA_FAILURE]: deactivateUserDataFailure,
  [userDataTypes.ACTIVATE_USER_DATA_START]: activateUserDataStart,
  [userDataTypes.ACTIVATE_USER_DATA_SUCCESS]: activateUserDataSuccess,
  [userDataTypes.ACTIVATE_USER_DATA_FAILURE]: activateUserDataFailure,
  [userDataTypes.DATA_RESET]: dataReset,
};
export const userDataReducer = createReducer(INITIAL_STATE, HANDLERS);
