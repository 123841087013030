/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Page, Container } from './CouponInstance.Styles';
import { CouponInstanceCreators } from './store/action';

import {
  FromContainer,
  FormTopContainer,
  ImageContainer,
  ImagePreView,
  ImageInput,
  ImageContainerLabel,
  ImageContainerBottomLabel,
  FormMiddleContainer,
  GeneralInputContainer,
  GeneralInputLabel,
  GeneralInputBox,
  GeneralRow,
  FormFooterContainer,
  SaveCouponInstanceButtonContainer,
  SaveCouponInstanceButton,
  CancelCouponInstanceButtonContainer,
  CancelCouponInstanceButton,
  HeaderContainer,
  HeaderRightContainer,
  EditCouponInstanceButtonContainer,
  EditCouponInstanceButton,
  ImageMessage,
  CouponsButtonContainer,
  CouponsButton,
  GeneralTextAreaBox,
} from './AddEditCouponInstance.Styles';
import { IMAGE_REGEX } from '../../constants/constant';
import { ErrorToast, WarningToast } from '../../services/toast/toast';
import { ToastPosition } from '../../constants/baseStyles';
import { checkValidity } from '../../constants/validation';
import { isEqual } from 'lodash';

const AddEditCouponInstance = () => {
  const dispatch = useDispatch();
  const selectedCouponInstanceObj = useSelector(
    (state) => state.couponInstance.couponInstanceDetails
  );
  const [companyId, setCompanyId] = useState('');
  const addCouponInstance = useMatch('company/details/:companyId/coupons/add');
  const updateCouponInstance = useMatch(
    'company/details/:companyId/coupons/details/:couponInstanceId'
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [couponInstance, setCouponInstance] = useState(selectedCouponInstanceObj);
  const [couponInstanceEdited, setCouponInstanceEdited] = useState(false);
  const [editClicked, setEditClicked] = useState(addCouponInstance !== null);
  const thumbnailFile = useRef(null);
  const bannerImageFile = useRef(null);
  const couponLogoFile = useRef(null);

  const loadingAdd = useSelector((state) => state.couponInstance.loadingAddCouponInstance);

  useEffect(() => {
    if (addCouponInstance === null) {
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      dispatch(CouponInstanceCreators.couponInstanceDetails({ couponInstanceId: id }));
    } else {
      dispatch(CouponInstanceCreators.addCouponInstanceSuccess());
      setCompanyId(addCouponInstance.params.companyId);
    }
  }, []);

  useEffect(() => {
    setCouponInstance(selectedCouponInstanceObj);
  }, [selectedCouponInstanceObj]);

  const onImageUploadClick = (editFor) => {
    //
    console.log(editFor);
    if (editFor === 'thumbnail') {
      thumbnailFile.current.click();
    } else if (editFor === 'couponLogo') {
      couponLogoFile.current.click();
    } else if (editFor === 'bannerImage') {
      bannerImageFile.current.click();
    }
  };

  // update function
  const onFieldsValueChange = (fieldName, value) => {
    try {
      const newSelectedCouponInstance = { ...couponInstance };
      if (fieldName === 'thumbnail') {
        //
        if (IMAGE_REGEX.test(value?.target?.files[0]?.name)) {
          //
          const objectUrl = URL.createObjectURL(value?.target?.files[0]);
          newSelectedCouponInstance.thumbnail.file = value?.target?.files[0];
          newSelectedCouponInstance.thumbnail.imageURL = objectUrl;
        } else {
          ErrorToast('Select Thumbnail', ToastPosition.TOP_CENTER, `ERROR`);
          return;
        }
      } else if (fieldName === 'couponLogo') {
        //
        if (IMAGE_REGEX.test(value?.target?.files[0]?.name)) {
          //
          const objectUrl = URL.createObjectURL(value?.target?.files[0]);
          newSelectedCouponInstance.couponLogo.file = value?.target?.files[0];
          newSelectedCouponInstance.couponLogo.imageURL = objectUrl;
        } else {
          ErrorToast('Select Coupon icon', ToastPosition.TOP_CENTER, `ERROR`);
          return;
        }
      } else if (fieldName === 'bannerImage') {
        //
        if (IMAGE_REGEX.test(value?.target?.files[0]?.name)) {
          //
          const objectUrl = URL.createObjectURL(value?.target?.files[0]);
          newSelectedCouponInstance.bannerImage.file = value?.target?.files[0];
          newSelectedCouponInstance.bannerImage.imageURL = objectUrl;
        } else {
          ErrorToast('Select Banner Image', ToastPosition.TOP_CENTER, `ERROR`);
          return;
        }
      } else if (fieldName === 'cardTitleColor') {
        newSelectedCouponInstance.styles.cardTitleColor = value.target.value;
      } else if (fieldName) {
        newSelectedCouponInstance[fieldName] = value.target.value;
      }
      setCouponInstance(newSelectedCouponInstance);
      if (!couponInstanceEdited) {
        setCouponInstanceEdited(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };
  // update function
  const onCancelClick = () => {
    if (addCouponInstance) {
      navigate('/');
    } else {
      setEditClicked(false);
      setCouponInstance(selectedCouponInstanceObj);
    }
  };

  // update function
  const onSaveClick = () => {
    //
    const {
      thumbnail,
      couponLogo,
      bannerImage,
      title,
      description,
      shortDescription,
      about,
      couponsPerUser,
      termsAndConditionsBasic,
      howToUse,
      redeemLink,
      couponExpire,
      termsAndConditionsLink,
      startDate,
      endDate,
      redeemByUsing,
      cost,
      styles,
    } = couponInstance;
    if (startDate === '') {
      ErrorToast('Start Date Cannot be empty', ToastPosition.TOP_CENTER, 'data-edit-failed');
      return;
    }
    if (endDate === '') {
      ErrorToast('Start Date Cannot be empty', ToastPosition.TOP_CENTER, 'data-edit-failed');
      return;
    }
    const sDate = moment(startDate).utc().set({ second: 0, millisecond: 0 });
    const eDate = moment(endDate).utc().set({ second: 0, millisecond: 0 });
    if (sDate.isAfter(eDate)) {
      ErrorToast('Start Date Cannot after End Date', ToastPosition.TOP_CENTER, 'data-edit-failed');
      return;
    }
    // edit mode
    if (addCouponInstance === null) {
      const {
        params: { couponInstanceId },
      } = updateCouponInstance;
      let dataEdited = false;
      const filesUpdated = thumbnail?.file || couponLogo?.file || bannerImage?.file;
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      const fileUpload = new FormData();
      if (filesUpdated) {
        //
        if (thumbnail?.file) {
          fileUpload.append('thumbnail', thumbnail.file);
        }
        if (couponLogo?.file) {
          fileUpload.append('coupon_logo', couponLogo.file);
        }
        if (bannerImage?.file) {
          fileUpload.append('banner_image', bannerImage.file);
        }
        dataEdited = true;
      }
      if (title !== selectedCouponInstanceObj.title) {
        //
        fileUpload.append('title', title);
        dataEdited = true;
      }
      if (about !== selectedCouponInstanceObj.about) {
        //
        fileUpload.append('about', about.trim());
        dataEdited = true;
      }
      if (description !== selectedCouponInstanceObj.description) {
        //
        fileUpload.append('description', description.trim());
        dataEdited = true;
      }
      if (cost !== selectedCouponInstanceObj.cost) {
        //
        fileUpload.append('cost', cost);
        dataEdited = true;
      }
      if (shortDescription !== selectedCouponInstanceObj.shortDescription) {
        //
        fileUpload.append('short_description', shortDescription.trim());
        dataEdited = true;
      }
      if (couponsPerUser !== selectedCouponInstanceObj.couponsPerUser) {
        //
        fileUpload.append('coupons_per_user', couponsPerUser);
        dataEdited = true;
      }
      if (termsAndConditionsBasic !== selectedCouponInstanceObj.termsAndConditionsBasic) {
        //
        const termsAndConditionsBasicArray = termsAndConditionsBasic.split('\n');
        fileUpload.append(
          'terms_and_conditions_basic',
          JSON.stringify({ terms_and_conditions_basic: termsAndConditionsBasicArray })
        );
        dataEdited = true;
      }
      if (howToUse !== selectedCouponInstanceObj.howToUse) {
        //
        const howToUseArray = howToUse.split('\n');
        fileUpload.append('how_to_use', JSON.stringify({ how_to_use: howToUseArray }));
        dataEdited = true;
      }
      if (redeemLink !== selectedCouponInstanceObj.redeemLink) {
        //
        fileUpload.append('redeem_link', redeemLink.trim());
        dataEdited = true;
      }
      if (termsAndConditionsLink !== selectedCouponInstanceObj.termsAndConditionsLink) {
        //
        fileUpload.append('termsAndConditionsLink', termsAndConditionsLink.trim());
        dataEdited = true;
      }
      if (startDate !== selectedCouponInstanceObj.startDate) {
        //
        fileUpload.append('start_date', startDate.trim());
        dataEdited = true;
      }
      if (endDate !== selectedCouponInstanceObj.endDate) {
        //
        fileUpload.append('end_date', endDate.trim());
        dataEdited = true;
      }
      if (
        couponExpire !== selectedCouponInstanceObj.couponExpire &&
        checkValidity(couponExpire, { isNumeric: true, minValue: -1 })
      ) {
        //
        fileUpload.append('expiring_in_days', couponExpire.trim());
        dataEdited = true;
      }
      fileUpload.append('styles', JSON.stringify({ color: styles.cardTitleColor }));
      dataEdited = true;
      const payload = {
        fileUpload,
        couponInstanceId: couponInstanceId,
      };
      if (!dataEdited) {
        WarningToast('Data is not edited', ToastPosition.TOP_CENTER, 'data-edit-failed');
      } else {
        dispatch(CouponInstanceCreators.editCouponInstance(payload));
      }
    } else {
      // for add mode
      let dataMissed = true;

      const fileUpload = new FormData();
      const filesUpdated = thumbnail?.file && couponLogo?.file && bannerImage?.file;
      if (filesUpdated) {
        //
        if (thumbnail?.file) {
          fileUpload.append('thumbnail', thumbnail.file);
        }
        if (couponLogo?.file) {
          fileUpload.append('coupon_logo', couponLogo.file);
        }
        if (bannerImage?.file) {
          fileUpload.append('banner_image', bannerImage.file);
        }
        dataMissed = false;
      }
      if (dataMissed) {
        WarningToast('Add All Images', ToastPosition.TOP_CENTER, 'Add-image');
        return;
      }
      dataMissed = title.trim() === '';
      if (dataMissed) {
        WarningToast('Title', ToastPosition.TOP_CENTER, 'Add-other-lan');
        return;
      }
      dataMissed = about.trim() === '';
      if (dataMissed) {
        WarningToast('About Coupon', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }

      dataMissed = description.trim() === '';
      if (dataMissed) {
        WarningToast('Add description', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed =
        couponsPerUser === '' &&
        checkValidity(couponsPerUser, { isNumeric: true, minValue: 1, greaterThanZero: true });
      if (dataMissed) {
        WarningToast('Add Coupons Per User', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed =
        couponExpire === '' && checkValidity(couponExpire, { isNumeric: true, minValue: -1 });
      if (dataMissed) {
        WarningToast(
          'Coupone Exprire should be more than 0 or -1',
          ToastPosition.TOP_CENTER,
          'Add-english'
        );
        return;
      }
      dataMissed = termsAndConditionsBasic.trim() === '';
      if (dataMissed) {
        WarningToast('Update Terms & Condition', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed =
        termsAndConditionsLink.trim() === '' &&
        checkValidity(termsAndConditionsLink, { isURL: true });
      if (dataMissed) {
        WarningToast('Update Terms & Condition Link', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed = howToUse.trim() === '';
      if (dataMissed) {
        WarningToast('Update How to Use', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed = startDate.trim() === '';
      if (dataMissed) {
        WarningToast('Update Start Date', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed = endDate.trim() === '';
      if (dataMissed) {
        WarningToast('Update End Date', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed = styles.cardTitleColor.trim() === '';
      if (dataMissed) {
        WarningToast('Add Card title color', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }

      const termsAndConditionsBasicArray = termsAndConditionsBasic.split('\n');
      const howToUseArray = howToUse.split('\n');

      fileUpload.append('thumbnail', thumbnail.file);
      fileUpload.append('coupon_logo', couponLogo.file);
      fileUpload.append('banner_image', bannerImage.file);
      fileUpload.append('title', title);
      fileUpload.append('about', about.trim());
      fileUpload.append('description', description.trim());
      fileUpload.append('short_description', shortDescription.trim());
      fileUpload.append('coupons_per_user', couponsPerUser);
      fileUpload.append('terms_and_conditions_link', termsAndConditionsLink);
      fileUpload.append(
        'terms_and_conditions_basic',
        JSON.stringify({ terms_and_conditions_basic: termsAndConditionsBasicArray })
      );
      fileUpload.append('how_to_use', JSON.stringify({ how_to_use: howToUseArray }));
      fileUpload.append('redeem_link', redeemLink.trim());
      fileUpload.append('start_date', startDate.trim());
      fileUpload.append('end_date', endDate.trim());
      fileUpload.append('redeem_by_using', redeemByUsing.trim());
      fileUpload.append('organization_id', companyId);
      fileUpload.append('cost', cost);
      fileUpload.append('styles', JSON.stringify({ color: styles.cardTitleColor }));
      fileUpload.append('expiring_in_days', couponExpire);
      const payload = {
        fileUpload,
      };
      dispatch(CouponInstanceCreators.addCouponInstance(payload));
    }
    //
  };

  const editClickHandler = (editFor, payload) => {
    if (!editClicked) {
      WarningToast('Enable Edit Mode.', ToastPosition.TOP_CENTER, 'data-edit-failed');
    } else {
      if (editFor === 'thumbnail' || editFor === 'couponLogo' || editFor === 'bannerImage') {
        onImageUploadClick(editFor);
      } else {
        onFieldsValueChange(editFor, payload);
      }
    }
  };

  const onCouponsClick = () => {
    navigate('add');
  };

  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderRightContainer>
            {addCouponInstance === null && !editClicked && (
              <CouponsButtonContainer>
                <CouponsButton onClick={onCouponsClick}>Add Coupons</CouponsButton>
              </CouponsButtonContainer>
            )}
            {addCouponInstance === null && !editClicked && (
              <EditCouponInstanceButtonContainer>
                <EditCouponInstanceButton
                  onClick={() => {
                    setEditClicked(true);
                  }}
                >
                  Edit Coupon Instance
                </EditCouponInstanceButton>
              </EditCouponInstanceButtonContainer>
            )}
          </HeaderRightContainer>
        </HeaderContainer>
        {couponInstance && (
          <FromContainer>
            <FormTopContainer>
              <GeneralRow>
                <ImageContainer onClick={() => editClickHandler('thumbnail')}>
                  <ImagePreView
                    src={
                      selectedCouponInstanceObj.thumbnail?.imageURL !== ''
                        ? selectedCouponInstanceObj.thumbnail.imageURL
                        : ''
                    }
                  />
                  <ImageMessage show={!addCouponInstance === null && editClicked}>
                    Click to edit image.
                  </ImageMessage>
                  <ImageInput
                    type={'file'}
                    onChange={(file) => onFieldsValueChange('thumbnail', file)}
                    ref={thumbnailFile}
                  />
                  {selectedCouponInstanceObj.thumbnail?.imageURL === '' && (
                    <ImageContainerLabel>{'Click to add a Thumbnail.'}</ImageContainerLabel>
                  )}
                  <ImageContainerBottomLabel>{'Thumbnail'}</ImageContainerBottomLabel>
                </ImageContainer>
                <ImageContainer onClick={() => editClickHandler('bannerImage')}>
                  <ImagePreView
                    src={
                      selectedCouponInstanceObj.bannerImage?.imageURL !== ''
                        ? selectedCouponInstanceObj.bannerImage?.imageURL
                        : ''
                    }
                  />
                  <ImageMessage show={!addCouponInstance === null && editClicked}>
                    Click to edit image.
                  </ImageMessage>
                  <ImageInput
                    type={'file'}
                    onChange={(file) => onFieldsValueChange('bannerImage', file)}
                    ref={bannerImageFile}
                  />
                  {selectedCouponInstanceObj.bannerImage?.imageURL === '' && (
                    <ImageContainerLabel>{'Click to add a Banner Image.'}</ImageContainerLabel>
                  )}
                  <ImageContainerBottomLabel>{'Banner Image'}</ImageContainerBottomLabel>
                </ImageContainer>
                <ImageContainer onClick={() => editClickHandler('couponLogo')}>
                  <ImagePreView
                    src={
                      selectedCouponInstanceObj.couponLogo?.imageURL !== ''
                        ? selectedCouponInstanceObj.couponLogo?.imageURL
                        : ''
                    }
                  />
                  <ImageMessage show={!addCouponInstance === null && editClicked}>
                    Click to edit image.
                  </ImageMessage>
                  <ImageInput
                    type={'file'}
                    onChange={(file) => onFieldsValueChange('couponLogo', file)}
                    ref={couponLogoFile}
                  />
                  {selectedCouponInstanceObj.couponLogo?.imageURL === '' && (
                    <ImageContainerLabel>{'Click to add a Coupon Icon.'}</ImageContainerLabel>
                  )}
                  <ImageContainerBottomLabel>{'Coupon Icon'}</ImageContainerBottomLabel>
                </ImageContainer>
              </GeneralRow>
            </FormTopContainer>
            <FormMiddleContainer>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Coupon Instance Title</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.title}
                    onChange={(event) => editClickHandler('title', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Description</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.description}
                    onChange={(event) => editClickHandler('description', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Short Description</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.shortDescription}
                    onChange={(event) => editClickHandler('shortDescription', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>About</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.about}
                    onChange={(event) => editClickHandler('about', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Redeem Using</GeneralInputLabel>
                  <GeneralInputBox
                    defaultValue={couponInstance.redeemByUsing}
                    disabled
                    // onChange={(event) => editClickHandler('redeemByUsing', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Coupons for one User</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.couponsPerUser}
                    onChange={(event) => editClickHandler('couponsPerUser', event)}
                    type="number"
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Cost of Coupon</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.cost}
                    onChange={(event) => editClickHandler('cost', event)}
                    type="number"
                  />
                </GeneralInputContainer>
              </GeneralRow>
              {addCouponInstance === null && (
                <GeneralRow>
                  <GeneralInputContainer>
                    <GeneralInputLabel>Total Coupons</GeneralInputLabel>
                    <GeneralInputBox defaultValue={couponInstance.totalCoupons} disabled />
                  </GeneralInputContainer>
                  <GeneralInputContainer>
                    <GeneralInputLabel>Available Coupons</GeneralInputLabel>
                    <GeneralInputBox defaultValue={couponInstance.availableCoupons} disabled />
                  </GeneralInputContainer>
                </GeneralRow>
              )}

              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Terms And Conditions Basic</GeneralInputLabel>
                  <GeneralTextAreaBox
                    value={couponInstance.termsAndConditionsBasic}
                    onChange={(event) => editClickHandler('termsAndConditionsBasic', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>How To Use</GeneralInputLabel>
                  <GeneralTextAreaBox
                    value={couponInstance.howToUse}
                    onChange={(event) => editClickHandler('howToUse', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Redeem Link</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.redeemLink}
                    onChange={(event) => editClickHandler('redeemLink', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Terms And Conditions Link</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.termsAndConditionsLink}
                    onChange={(event) => editClickHandler('termsAndConditionsLink', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Card Title Color</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.styles.cardTitleColor}
                    onChange={(event) => editClickHandler('cardTitleColor', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Start Date</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.startDate}
                    onChange={(event) => editClickHandler('startDate', event)}
                    type="datetime-local"
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>End Date</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.endDate}
                    onChange={(event) => editClickHandler('endDate', event)}
                    type="datetime-local"
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Coupon Expire(in Days)</GeneralInputLabel>
                  <GeneralInputBox
                    value={couponInstance.couponExpire}
                    onChange={(event) => editClickHandler('couponExpire', event)}
                    type="number"
                  />
                </GeneralInputContainer>
              </GeneralRow>
            </FormMiddleContainer>
            {editClicked && (
              <FormFooterContainer>
                <SaveCouponInstanceButtonContainer>
                  <SaveCouponInstanceButton
                    onClick={loadingAdd ? () => {} : onSaveClick}
                    loadingAdd={loadingAdd}
                    cursorPointer={true}
                  >
                    {loadingAdd ? 'Adding...' : 'Save'}
                  </SaveCouponInstanceButton>
                </SaveCouponInstanceButtonContainer>
                <CancelCouponInstanceButtonContainer>
                  <CancelCouponInstanceButton onClick={onCancelClick}>
                    Cancel
                  </CancelCouponInstanceButton>
                </CancelCouponInstanceButtonContainer>
              </FormFooterContainer>
            )}
          </FromContainer>
        )}
      </Container>
    </Page>
  );
};

export default AddEditCouponInstance;
