import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  backgroundSecondayColor,
  borderSecondayColor,
  boxShadowStyle,
  LATO,
  textPrimaryColor,
} from '../../constants/baseStyles';

const Page = styled.div`
  background-color: ${backgroundSecondayColor};
`;

const DashboardContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  background-color: ${backgroundSecondayColor};
`;

const CardsContainer = styled.div`
  padding: 9px 0;
  margin-bottom: 5px;
`;

const CardContainerLink = styled(Link)`
  position: relative;
  display: inline-block;
  height: 105px;
  width: 120px;
  margin: 5px 15px 5px 5px;
  border-radius: 5px;
  background-color: #ffffff;
  border-top: ${borderSecondayColor} 2.5px solid;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 400ms ease;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  &:hover p {
    font-weight: 700;
  }
`;

const CardIconContainer = styled.div`
  position: relative;
  margin-top: 12px;
  margin-bottom: 8px;
  width: 45px;
  height: 45px;
  transition: all 400ms ease;
`;

const CardIcon = styled.div``;

const CardTitleContainer = styled.div``;

const CardTitleText = styled.p`
  position: relative;
  font-size: 0.8rem;
  color: ${textPrimaryColor};
  font-family: ${LATO};
  word-break: normal;
  transition: all 400ms ease;
`;

export {
  Page,
  DashboardContainer,
  CardsContainer,
  CardContainerLink,
  CardIconContainer,
  CardIcon,
  CardTitleContainer,
  CardTitleText,
};
