import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // login actions
  login: ['payload'],
  loginStart: null,
  loginSuccess: ['payload'],
  loginFailed: ['payload'],
  // logout actions
  logout: ['payload'],
  logoutStart: null,
  logoutSuccess: ['payload'],
  logoutFailed: ['payload'],
});

export { Types, Creators };
