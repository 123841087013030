const PageNotFound = () => {
  return (
    <>
      <div>
        <div>404 page not found zasdb askdh asilukjdb ilasugdb kaswhyuvd</div>
      </div>
    </>
  );
};

export default PageNotFound;
