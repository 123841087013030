import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout.View';

import Login from '../pages/Auth/Login.View';
import { useSelector } from 'react-redux';
import BioScope from '../pages/BioScope/BioScope.View';
import ApproveBioScope from '../pages/BioScope/ApproveBioScope.View';
import Dashboard from '../pages/Dashboard/Dashboard.View';
import PageNotFound from '../pages/ErrorView/PageNotFound.View';
import Users from '../pages/Users/Users.View';
import NewUser from '../pages/NewUser/NewUser.View';
import PrivateRoute from './PrivateRoute';
import AddEditBioScope from '../pages/BioScope/AddEditBioScope.View';
import AddMovies from '../pages/Movies/AddMovies.View';
import BioScopeCampaign from '../pages/Campaigns/Campaign.View';
import AddEditDetailsCampaign from '../pages/Campaigns/AddEditDetailsCampaign.View';
import Notification from '../pages/Notification/Notification.View';
import AddNotification from '../pages/Notification/AddNotification.View';
import Company from '../pages/Company/Company.View';
import AddEditCompany from '../pages/Company/AddEditCompany.View';
import CouponInstance from '../pages/CouponInstance/CouponInstance.View';
import AddEditCouponInstance from '../pages/CouponInstance/AddEditCouponInstance.View';
import AddUserCoupons from '../pages/CouponInstance/AddUserCoupons.View';

const NavigationRoutes = () => {
  const userType = useSelector((state) => state.auth.userType);
  return (
    <div>
      <Routes>
        {userType ? (
          <>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <PrivateRoute type={'ALL'}>
                    <Dashboard />
                  </PrivateRoute>
                }
                errorElement={<PageNotFound />}
              />
              <Route path="/users">
                <Route
                  index
                  element={
                    <PrivateRoute type={'ALL'}>
                      <Users />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="add"
                  element={
                    <PrivateRoute type={'ALL'}>
                      <NewUser />
                    </PrivateRoute>
                  }
                ></Route>
              </Route>
              <Route path="/bio-scope">
                <Route
                  index
                  element={
                    <PrivateRoute type={'ALL'}>
                      <BioScope />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="add"
                  element={
                    <PrivateRoute>
                      <AddEditBioScope />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="details/:bioscopeId"
                  element={
                    <PrivateRoute>
                      <AddEditBioScope />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="approval"
                  element={
                    <PrivateRoute>
                      <ApproveBioScope />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="approval/details/:bioscopeId"
                  element={
                    <PrivateRoute>
                      <AddEditBioScope />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/movies">
                <Route
                  path="add"
                  element={
                    <PrivateRoute type={'ALL'}>
                      <AddMovies />
                    </PrivateRoute>
                  }
                ></Route>
              </Route>
              <Route path="/bio-scope-campaign">
                <Route
                  index
                  element={
                    <PrivateRoute type={'ALL'}>
                      <BioScopeCampaign />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="add"
                  element={
                    <PrivateRoute type={'ALL'}>
                      <AddEditDetailsCampaign />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/notification">
                <Route
                  index
                  element={
                    <PrivateRoute type={'ALL'}>
                      <Notification />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="add"
                  element={
                    <PrivateRoute>
                      <AddNotification />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/company">
                <Route
                  index
                  element={
                    <PrivateRoute type={'ALL'}>
                      <Company />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="add"
                  element={
                    <PrivateRoute>
                      <AddEditCompany />
                    </PrivateRoute>
                  }
                />
                <Route path="details/:companyId">
                  <Route
                    index
                    element={
                      <PrivateRoute>
                        <AddEditCompany />
                      </PrivateRoute>
                    }
                  />
                  <Route path="coupons">
                    <Route
                      index
                      element={
                        <PrivateRoute>
                          <CouponInstance />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="add"
                      element={
                        <PrivateRoute>
                          <AddEditCouponInstance />
                        </PrivateRoute>
                      }
                    />
                    <Route path="details/:couponInstanceId">
                      <Route
                        index
                        element={
                          <PrivateRoute>
                            <AddEditCouponInstance />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="add"
                        element={
                          <PrivateRoute>
                            <AddUserCoupons />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<PageNotFound />}></Route>
          </>
        )}
      </Routes>
    </div>
  );
};

export default NavigationRoutes;
