import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination.View';
import { PlusIcon } from '../../constants/globalStyles.Styles';
import {
  Page,
  Container,
  HeaderContainer,
  HeaderLeftContainer,
  HeaderTitleContainer,
  HeaderTitle,
  HeaderRightContainer,
  AddBioScopeButtonContainer,
  AddBioScopeButton,
  BioScopeTableContainer,
  BioScopeTable,
  BioScopeThead,
  BioScopeTH,
  BioScopeTBoddy,
  BioScopeTR,
  BioScopeTD,
  BioScopeApprovedColumn,
  LoadingFiller,
} from './Campaign.Styles';

const BioScopeCampaign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingBiosopeList = useSelector((state) => state.campaignData.loadingBioSopeList);
  const allbioScopes = [];

  const visitDetails = (e) => {
    navigate(`details/${e}`);
  };

  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderLeftContainer>
            <HeaderTitleContainer>
              <HeaderTitle>BioScope Campaign</HeaderTitle>
            </HeaderTitleContainer>
          </HeaderLeftContainer>
          <HeaderRightContainer>
            <AddBioScopeButtonContainer>
              <AddBioScopeButton to={'add'}>
                <PlusIcon />
                Add Campaign
              </AddBioScopeButton>
            </AddBioScopeButtonContainer>
          </HeaderRightContainer>
        </HeaderContainer>
        <BioScopeTableContainer>
          <BioScopeTable>
            <BioScopeThead>
              <BioScopeTR>
                <BioScopeTH>Campaign Name</BioScopeTH>
                <BioScopeTH>Addition Date</BioScopeTH>
                <BioScopeTH>Added by</BioScopeTH>
                <BioScopeTH>Approved/Rejected BY</BioScopeTH>
                <BioScopeTH>Approval By</BioScopeTH>
              </BioScopeTR>
            </BioScopeThead>
            <BioScopeTBoddy>
              {loadingBiosopeList ? (
                <BioScopeTR>
                  <BioScopeTD>
                    <LoadingFiller>Fetching Data...</LoadingFiller>
                  </BioScopeTD>
                </BioScopeTR>
              ) : (
                allbioScopes.map((bioScope, id) => {
                  return (
                    <BioScopeTR key={id.toString()} onClick={() => visitDetails(bioScope.id)}>
                      <BioScopeTD>{bioScope.answer?.name}</BioScopeTD>
                      <BioScopeTD>{bioScope.date}</BioScopeTD>
                      <BioScopeTD> {bioScope.added_by} </BioScopeTD>
                      <BioScopeTD> {bioScope.rejected_by} </BioScopeTD>
                      <BioScopeTD>
                        <BioScopeApprovedColumn approve={bioScope.approved}>
                          {bioScope.approved === true
                            ? 'Approved'
                            : bioScope.approved_rejected_by === ''
                            ? 'Waiting for Approval'
                            : 'Rejected'}
                        </BioScopeApprovedColumn>
                      </BioScopeTD>
                    </BioScopeTR>
                  );
                })
              )}
            </BioScopeTBoddy>
          </BioScopeTable>
          <Pagination
          // total_pages={total_page}
          // pagesFor={'bioscopes'}
          // page_size={pageSize}
          ></Pagination>
        </BioScopeTableContainer>
      </Container>
    </Page>
  );
};

export default BioScopeCampaign;
