import { put, call } from 'redux-saga/effects';
import { NotificationCreators } from './action';
import { ToastPosition } from '../../../constants/baseStyles';
import { ErrorToast, SuccessToast } from '../../../services/toast/toast';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';

export function* loadNotificationListSaga(action) {
  const { pageNumber } = action.payload;
  try {
    yield put(NotificationCreators.loadNotificationListStart());
    let response;
    if (pageNumber > 1) {
      response = yield call(ApiService.get, apiEndPoints.notificationList, {
        params: {
          page: pageNumber,
          page_size: 4,
        },
      });
    } else {
      response = yield call(ApiService.get, apiEndPoints.notificationList, {
        params: {
          page_size: 4,
          page: 1,
        },
      });
    }
    if (response.status >= 200 && response.status < 300) {
      const { push_notifications, total_pages } = response.data.data;
      const payload = {
        pushNotifications: push_notifications,
        totalPages: total_pages,
      };
      yield put(NotificationCreators.loadNotificationListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(NotificationCreators.loadNotificationListFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}

export function* addNotificationSaga(action) {
  try {
    const {
      payload: { title, message },
    } = action;

    yield put(NotificationCreators.addNotificationStart());

    const apiDataObj = {
      title,
      body: message,
    };
    const response = yield call(ApiService.post, apiEndPoints.addNotification, apiDataObj);
    if (response.status >= 200 && response.status < 300) {
      console.log(response.data);
      yield put(NotificationCreators.addNotificationSuccess());
      SuccessToast('Notification Sent Successfully', ToastPosition.TOP_CENTER, 'success1');
    } else {
      throw response;
    }
  } catch (error) {
    yield put(NotificationCreators.addNotificationFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}
