const FILE_SELECTOR_INITIAL_OBJ_BIOSCOPE = {
  files: [
    {
      id: 'IMAGE_1',
      label: 'IMAGE 1',
      file: null,
      imageURL: '',
      index: 0,
    },
    {
      id: 'IMAGE_2',
      label: 'IMAGE 2',
      file: null,
      imageURL: '',
      index: 1,
    },
    {
      id: 'IMAGE_3',
      label: 'IMAGE 3',
      file: null,
      imageURL: '',
      index: 2,
    },
    {
      id: 'IMAGE_4',
      label: 'IMAGE 4',
      file: null,
      imageURL: '',
      index: 3,
    },
    {
      id: 'IMAGE_5',
      label: 'IMAGE 5',
      file: null,
      imageURL: '',
      index: 4,
    },
  ],
  startDate: '',
  endDate: '',
  rightAnswerEnglish: '',
  rightAnswerOtherLang: '',
  answerId: 0,
};

const CAMPAIGN_DETAILS_OBJ = {
  name: '',
  localName: '',
  startDate: '',
  endDate: '',
};

const COMPANY_DETAILS_OBJ = {
  companyName: '',
  logo: {
    id: 'IMAGE_LOGO',
    label: 'Logo',
    file: null,
    imageURL: '',
    index: 0,
  },
  contactPersonPrimary: {
    name: '',
    contactNo: '',
    email: '',
  },
  contactPersonSecondary: {
    name: '',
    contactNo: '',
    email: '',
  },
  about: '',
  gst: '',
};

const COUPON_INSTANCE_OBJ = {
  organizationId: 0,
  totalCoupons: 0,
  availableCoupons: 0,
  couponsPerUser: 1,
  cost: 1,
  redeemByUsing: 'coins',
  bannerImage: {
    id: 'IMAGE_BANNER',
    label: 'Banner Image',
    file: null,
    imageURL: '',
    index: 0,
  },
  thumbnail: {
    id: 'IMAGE_THUMBNAIL',
    label: 'thumbnail',
    file: null,
    imageURL: '',
    index: 0,
  },
  title: '',
  description: '',
  howToUse: '',
  redeemLink: '',
  termsAndConditionsBasic: '',
  termsAndConditionsLink: '',
  startDate: '',
  endDate: '',
  shortDescription: '',
  couponLogo: {
    id: 'IMAGECOUPON_LOGO',
    label: 'Coupon Icon',
    file: null,
    imageURL: '',
    index: 0,
  },
  about: '',
  styles: {
    cardTitleColor: '',
  },
  couponExpire: -1,
};

const COUPON_REDDEM_BY_USING = {
  conis: 'conins',
  points: 'points',
};

const PAGES = {
  ORGANISATION: {
    title: 'Organisations',
    addButtonText: 'Add Organisation',
    addButtonNavigation: 'add',
  },
  COUPONINSTANCE: {
    title: 'Coupon Instance',
    addButtonText: 'Add Coupon Instance',
    addButtonNavigation: 'add',
  },
};

const IMAGE_REGEX = /[\/.](jpg|jpeg|png)$/i;
const CSV_REGEX = /[\/.](csv)$/i;

const IS_DEV = false;

const DEBOUNCE_TIME = 300;

export {
  FILE_SELECTOR_INITIAL_OBJ_BIOSCOPE,
  IMAGE_REGEX,
  CAMPAIGN_DETAILS_OBJ,
  IS_DEV,
  PAGES,
  COMPANY_DETAILS_OBJ,
  COUPON_INSTANCE_OBJ,
  CSV_REGEX,
  COUPON_REDDEM_BY_USING,
  DEBOUNCE_TIME,
};
