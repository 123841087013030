import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const userType = useSelector((state) => state.auth.userType);

  let location = useLocation();
  useEffect(() => {}, [location]);
  if (userType) {
    return children;
  }
  if (!userType) {
    <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Navigate to="/pagenotfound" state={{ from: location }} replace />;
};

export default PrivateRoute;
