import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  backgroundColor,
  backgroundSecondayColor,
  borderSecondayColor,
  boxShadowStyle,
  buttonBackgroundPrimaryColor,
  buttonTextPrimaryColor,
  LATO,
  OPEN_SANS,
  titleTextColor,
  textPrimaryColor,
  borderPrimaryColor,
} from '../../constants/baseStyles';

const Page = styled.div`
  display: flex;
  background-color: ${backgroundSecondayColor};
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 58px);
`;

const Container = styled.div`
  margin: 5px 5px 0 5px;
  width: 99%;
  min-height: calc(100vh - 58px);
  border-top: 3px solid ${borderSecondayColor};
  border-radius: 5px;
  background-color: ${backgroundColor};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 0 25px;
`;

const HeaderLeftContainer = styled.div``;

const HeaderTitleContainer = styled.div``;

const HeaderTitle = styled.h3`
  font-size: 24px;
  color: ${titleTextColor};
  font-weight: 400;
  font-family: ${LATO};
  lint-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const HeaderRightContainer = styled.div``;

const AddBioScopeButtonContainer = styled.div`
  background-color: ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
`;

const AddBioScopeButton = styled(Link)`
  display: flex;
  color: ${buttonTextPrimaryColor};
  font-family: ${OPEN_SANS};
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  text-decoration: none;
`;

const BioScopeTableContainer = styled.div`
  display: flex;
  font-family: ${OPEN_SANS};
  border-collapse: collapse;
  margin-top: 20px;
  overflow-y: scroll;
`;

const BioScopeTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  padding: 20px;
`;

const BioScopeThead = styled.thead``;

const BioScopeTH = styled.th`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: ${textPrimaryColor};
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid ${borderPrimaryColor};
  font-family: ${OPEN_SANS};
`;

const BioScopeTBoddy = styled.tbody`
  height: 60vh;
  overflow-y: scroll;
`;

const BioScopeTR = styled.tr`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const BioScopeTD = styled.td`
  display: flex;
  flex-direction: row;
  color: ${textPrimaryColor};
  border-bottom: 1px solid ${borderPrimaryColor};
  font-family: ${OPEN_SANS};
  width: 100%;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const BioScopeApprovedColumn = styled.p`
  color: ${(props) => (props.approve === true ? 'green' : 'red')};
`;

const ApproveButton = styled.div`
  display: flex;
  color: ${buttonTextPrimaryColor};
  background-color: ${buttonBackgroundPrimaryColor};
  font-family: ${OPEN_SANS};
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 4px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
`;
const BioScopeImage = styled.img`
  width: 80px;
  height: 60px;
`;
const DataNotAvailable = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 20px;
  font-family: ${OPEN_SANS};
`;

const LoadingFiller = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  font-family: ${OPEN_SANS};
`;
export {
  Page,
  Container,
  HeaderContainer,
  HeaderLeftContainer,
  HeaderTitleContainer,
  HeaderTitle,
  HeaderRightContainer,
  AddBioScopeButtonContainer,
  AddBioScopeButton,
  BioScopeTableContainer,
  BioScopeTable,
  BioScopeThead,
  BioScopeTH,
  BioScopeTBoddy,
  BioScopeTR,
  BioScopeTD,
  BioScopeApprovedColumn,
  ApproveButton,
  BioScopeImage,
  DataNotAvailable,
  LoadingFiller,
};
