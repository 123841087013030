import styled from 'styled-components';
const PlusIcon = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: 1px;
  margin-left: -3px;
  position: relative;
  width: 20px;
  height: 20px;
  &:before {
    content: '';
    position: absolute;
    background: #fff;
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 2px;
    transform: translateX(-50%);
  }
  &:after {
    content: '';
    position: absolute;
    background: #fff;
    top: 50%;
    left: 4px;
    right: 4px;
    height: 2px;
    transform: translateY(-50%);
  }
`;

export { PlusIcon };
