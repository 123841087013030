import { useState } from 'react';
import { ApiServiceMultipart } from '../../services/axios/apiService';
import { rootConfig } from '../../services/axios/endPoint';
import {
  Page,
  AddFileContainer,
  AddFileHeading,
  AddFileTop,
  AddFileLabel,
  AddFileInput,
  AddFileFormContent,
  AddFileButton,
  RequireInput,
} from './AddMovies.Styles';
const AddMovies = () => {
  const [file, setFile] = useState(null);
  const onFileChange = (file) => {
    setFile(file.target.files[0]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('csv_file', file);
    if (file.name.endsWith('.csv')) {
      ApiServiceMultipart.post(`${rootConfig.apiRoot}/bioscope/movie/add-csv/`, data)
        .then((res) => {
          if (res.status === 200) {
            // toast.success(" File Uploaded SuccessFully");
          } else {
          }
        })
        .catch((error) => {});
    } else {
    }
  };
  return (
    <Page>
      <AddFileContainer>
        <AddFileTop>
          <AddFileHeading>Upload File</AddFileHeading>
        </AddFileTop>
        <AddFileFormContent
          id="file-form"
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <AddFileLabel>
            Upload your file<RequireInput>*</RequireInput>
          </AddFileLabel>
          <AddFileInput type="file" accept={'.csv'} onChange={onFileChange} />
          <AddFileButton type="submit" form="file-form">
            Upload
          </AddFileButton>
        </AddFileFormContent>
      </AddFileContainer>
    </Page>
  );
};

export default AddMovies;
