import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // GET Company List actions
  fetchCompanyList: ['payload'],
  fetchCompanyListStart: null,
  fetchCompanyListSuccess: ['payload'],
  fetchCompanyListFailure: ['payload'],
  //  Company Details actions
  companyDetails: ['payload'],
  companyDetailsStart: null,
  companyDetailsSuccess: ['payload'],
  companyDetailsFailure: ['payload'],
  // Add Company  actions
  addCompany: ['payload'],
  addCompanyStart: null,
  addCompanySuccess: ['payload'],
  addCompanyFailure: ['payload'],
  // Edit Company  actions
  editCompany: ['payload'],
  editCompanyStart: null,
  editCompanySuccess: ['payload'],
  editCompanyFailure: ['payload'],
});

export { Types as CompanyTypes, Creators as CompanyCreators };
