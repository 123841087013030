/**
 * reducer.js for Bio Scope
 *
 * Author
 *  1. Sourabh Kumar
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { FILE_SELECTOR_INITIAL_OBJ_BIOSCOPE } from '../../../constants/constant';
import { BioScopeTypes } from './action';

const INITIAL_STATE = {
  selectedBioScopeObj: { ...FILE_SELECTOR_INITIAL_OBJ_BIOSCOPE },
  loadingBioSopeList: false,
  loadingBioScopeDetails: false,
  loadingAddBioScope: false,
  loadingApproveBioScope: false,
  bioScopeList: [],
  bioScopeFrames: [],
  bioScopeListTotalPage: 1,
  bioScopeListCurrentPage: 1,
  loading: false,
  suggestions: [],
};

// bioscopeMovieSuggestion
const bioscopeMovieSuggestionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const bioscopeMovieSuggestionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    suggestions: action.payload.users,
  };
};
const bioscopeMovieSuggestionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
  };
};

const fetchBioScopeListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingBioSopeList: true,
});

const fetchBioScopeListSuccess = (state = INITIAL_STATE, action) => {
  const data = action.payload.bioscopes;
  const total_page = action.payload.total_pages;
  return {
    ...state,
    loadingBioSopeList: false,
    // bioScopeListTotalPage: state.bioScopeListCurrentPage,
    // bioScopeListCurrentPage: newPageNumber,
    bioScopeList: data,
    bioScopeListTotalPage: total_page,
  };
};

const fetchBioScopeListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingBioSopeList: false,
});

const addBioScopeStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddBioScope: true,
});

const addBioScopeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddBioScope: false,
    selectedBioScopeObj: { ...FILE_SELECTOR_INITIAL_OBJ_BIOSCOPE },
  };
};

const addBioScopeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddBioScope: false,
});

const bioScopeDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingBioScopeDetails: true,
});

const bioScopeDetailsSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { bioScopeDetails },
  } = action;
  return {
    ...state,
    loadingBioScopeDetails: false,
    selectedBioScopeObj: bioScopeDetails,
  };
};

const bioScopeDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingBioScopeDetails: false,
});

const editBioScopeStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddBioScope: true,
});

const editBioScopeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddBioScope: false,
  };
};

const editBioScopeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddBioScope: false,
});

const approveRejectBioScopeStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingApproveBioScope: true,
});

const approveRejectBioScopeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingApproveBioScope: false,
  };
};

const approveRejectBioScopeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingApproveBioScope: false,
});

const HANDLERS = {
  [BioScopeTypes.FETCH_BIO_SCOPE_LIST_START]: fetchBioScopeListStart,
  [BioScopeTypes.FETCH_BIO_SCOPE_LIST_SUCCESS]: fetchBioScopeListSuccess,
  [BioScopeTypes.FETCH_BIO_SCOPE_LIST_FAILURE]: fetchBioScopeListFailure,

  [BioScopeTypes.ADD_BIO_SCOPE_START]: addBioScopeStart,
  [BioScopeTypes.ADD_BIO_SCOPE_SUCCESS]: addBioScopeSuccess,
  [BioScopeTypes.ADD_BIO_SCOPE_FAILURE]: addBioScopeFailure,

  [BioScopeTypes.EDIT_BIO_SCOPE_START]: editBioScopeStart,
  [BioScopeTypes.EDIT_BIO_SCOPE_SUCCESS]: editBioScopeSuccess,
  [BioScopeTypes.EDIT_BIO_SCOPE_FAILURE]: editBioScopeFailure,

  [BioScopeTypes.BIO_SCOPE_DETAILS_START]: bioScopeDetailsStart,
  [BioScopeTypes.BIO_SCOPE_DETAILS_SUCCESS]: bioScopeDetailsSuccess,
  [BioScopeTypes.BIO_SCOPE_DETAILS_FAILURE]: bioScopeDetailsFailure,

  [BioScopeTypes.APPROVE_REJECT_BIO_SCOPE_START]: approveRejectBioScopeStart,
  [BioScopeTypes.APPROVE_REJECT_BIO_SCOPE_SUCCESS]: approveRejectBioScopeSuccess,
  [BioScopeTypes.APPROVE_REJECT_BIO_SCOPE_FAILURE]: approveRejectBioScopeFailure,

  [BioScopeTypes.BIOSCOPE_MOVIE_SUGGESTION_START]: bioscopeMovieSuggestionStart,
  [BioScopeTypes.BIOSCOPE_MOVIE_SUGGESTION_SUCCESS]: bioscopeMovieSuggestionSuccess,
  [BioScopeTypes.BIOSCOPE_MOVIE_SUGGESTION_FAILURE]: bioscopeMovieSuggestionFailure,
};

export const bioScopeReducer = createReducer(INITIAL_STATE, HANDLERS);
