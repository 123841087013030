import { useEffect, useState, useRef } from 'react';
import { cookie } from '../../services/cookies/cookieServices';
import { useMatch, Outlet, useNavigate } from 'react-router-dom';
import {
  Page,
  Sidebar,
  SidebarTopSpacing,
  SidebarLogoContainer,
  SidebarLogo,
  SidebarIconsContainer,
  SidebarIconsLink,
  SidebarIcons,
  SidebarIconsText,
  RightBoxContent,
  TopBar,
  TopBarLeftContainer,
  BackButtonContainer,
  BackButton,
  BackButtonIcon,
  PageTitleContainer,
  PageTitle,
  TopBarRightContainer,
  RightMenuContainer,
  RightMenuIcon,
  UserNameContainer,
  UserNameText,
  LogOutModalContainer,
  LogOutModal,
  CarretUpIcon,
} from './Layout.Styles';

const Layout = () => {
  const [pageTitle, setPageTitle] = useState('Home');
  const matchPathUsers = useMatch('/users/*');
  const matchPathBioScope = useMatch('/bio-scope/');
  const matchPathDashboard = useMatch('/');
  const matchPathBioScopeApproval = useMatch('/bio-scope/approval/*');
  const matchPathMovieAdd = useMatch('/movies/add/*');
  const matchPathNotification = useMatch('/notification/*');
  const companyDetails = useMatch('company/details/:companyId');
  const companyList = useMatch('company');
  const companyAdd = useMatch('company/add');
  const couponInstanceURL = useMatch('company/details/:companyId/coupons');
  const addCouponInstance = useMatch('company/details/:companyId/coupons/add');
  const updateCouponInstance = useMatch(
    'company/details/:companyId/coupons/details/:couponInstanceId'
  );
  const logOutRef = useRef();
  const [logoutIconclicked, setLogoutIconclicked] = useState(false);
  const navigate = useNavigate();
  const userDetails = cookie.get('userDetails');
  const dotIndex = userDetails.email.indexOf('.');
  const atIndex = userDetails.email.indexOf('@');
  const firstIndex = atIndex < dotIndex ? atIndex : dotIndex;
  let userName = userDetails.email.substring(0, firstIndex);
  userName =
    userDetails.email.substring(0, 1).toUpperCase() + userDetails.email.substring(1, firstIndex);
  const handleSignOut = () => {
    cookie.remove('userDetails');
    navigate('/');
    window.location.reload(true);
  };

  useEffect(() => {
    if (matchPathDashboard) {
      setPageTitle('Home');
    } else if (matchPathUsers) {
      setPageTitle('User');
    } else if (matchPathBioScope) {
      setPageTitle('Bio Scope');
    } else if (matchPathBioScopeApproval) {
      setPageTitle('Bio Scope Approval');
    } else if (matchPathMovieAdd) {
      setPageTitle('Add Movie');
    } else if (companyList) {
      setPageTitle('Organisations');
    } else if (companyAdd) {
      setPageTitle('Add Organisation');
    } else if (companyDetails) {
      setPageTitle('Organisation Details');
    } else if (matchPathNotification) {
      setPageTitle('Notification');
    } else if (couponInstanceURL) {
      setPageTitle('Coupons');
    } else if (addCouponInstance) {
      setPageTitle('Add Coupons Instance');
    } else if (updateCouponInstance) {
      setPageTitle('Coupons Instance');
    }
  }, [
    matchPathDashboard,
    matchPathUsers,
    matchPathBioScope,
    matchPathBioScopeApproval,
    matchPathMovieAdd,
    matchPathNotification,
    companyDetails,
    companyList,
    companyAdd,
    couponInstanceURL,
    addCouponInstance,
    updateCouponInstance,
  ]);

  const handleClickOutside = (event) => {
    if (logOutRef.current && !logOutRef.current.contains(event.target)) {
      setLogoutIconclicked(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [logOutRef]);

  return (
    <Page>
      <Sidebar>
        <SidebarTopSpacing />
        <SidebarLogoContainer to="/">
          <SidebarLogo />
        </SidebarLogoContainer>

        {userDetails.userType === 'Super Admin' && (
          <SidebarIconsContainer active={matchPathUsers}>
            <SidebarIconsLink to="/users">
              <SidebarIcons>
                <SidebarIconsText>Users</SidebarIconsText>
              </SidebarIcons>
            </SidebarIconsLink>
          </SidebarIconsContainer>
        )}

        <SidebarIconsContainer active={matchPathBioScope && !matchPathBioScopeApproval}>
          <SidebarIconsLink to="/bio-scope">
            <SidebarIcons>
              <SidebarIconsText>Bio Scope</SidebarIconsText>
            </SidebarIcons>
          </SidebarIconsLink>
        </SidebarIconsContainer>

        {userDetails.userType === 'Super Admin' && (
          <SidebarIconsContainer active={matchPathBioScopeApproval}>
            <SidebarIconsLink to="/bio-scope/approval">
              <SidebarIcons>
                <SidebarIconsText>Bio Scope Approval</SidebarIconsText>
              </SidebarIcons>
            </SidebarIconsLink>
          </SidebarIconsContainer>
        )}

        <SidebarIconsContainer active={matchPathMovieAdd}>
          <SidebarIconsLink to="/movies/add">
            <SidebarIcons>
              <SidebarIconsText>Add Movie</SidebarIconsText>
            </SidebarIcons>
          </SidebarIconsLink>
        </SidebarIconsContainer>

        <SidebarIconsContainer active={matchPathNotification}>
          <SidebarIconsLink to="/notification">
            <SidebarIcons>
              <SidebarIconsText>Notification</SidebarIconsText>
            </SidebarIcons>
          </SidebarIconsLink>
        </SidebarIconsContainer>
      </Sidebar>
      <RightBoxContent>
        <TopBar>
          <TopBarLeftContainer>
            <BackButtonContainer goBackButtonVisible={!matchPathDashboard}>
              <BackButton onClick={() => navigate(-1)}>
                <BackButtonIcon />
              </BackButton>
            </BackButtonContainer>
            <PageTitleContainer>
              <PageTitle>{pageTitle}</PageTitle>
            </PageTitleContainer>
          </TopBarLeftContainer>
          <TopBarRightContainer>
            <UserNameContainer>
              <UserNameText>{userName}</UserNameText>
            </UserNameContainer>
            <RightMenuContainer
              onClick={() => {
                setLogoutIconclicked(!logoutIconclicked);
              }}
            >
              <RightMenuIcon></RightMenuIcon>

              {logoutIconclicked && (
                <LogOutModalContainer>
                  {' '}
                  <CarretUpIcon></CarretUpIcon>
                  <LogOutModal onClick={handleSignOut} ref={logOutRef}>
                    Logout
                  </LogOutModal>
                </LogOutModalContainer>
              )}
            </RightMenuContainer>
          </TopBarRightContainer>
        </TopBar>

        <Outlet />
      </RightBoxContent>
    </Page>
  );
};

export default Layout;
