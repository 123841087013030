/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { debounce } from 'lodash';

import { CSV_REGEX, DEBOUNCE_TIME } from '../../constants/constant';
import { CouponInstanceCreators } from './store/action';
import {
  Page,
  AddFileContainer,
  AddFileTop,
  AddFileLabel,
  AddFileInput,
  AddFileFormContent,
  AddFileButton,
  RequireInput,
} from './AddUserCoupons.Styles';
import { ErrorToast } from '../../services/toast/toast';
import { ToastPosition } from '../../constants/baseStyles';
import { useEffect } from 'react';
const AddUserCoupons = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const addUserCouponError = useSelector((state) => state.couponInstance.addUserCouponError);
  const addUserCouponLoading = useSelector((state) => state.couponInstance.addUserCouponLoading);
  const refOfFileUpload = useRef(null);

  const updateCouponInstance = useMatch(
    'company/details/:companyId/coupons/details/:couponInstanceId/add'
  );

  useEffect(() => {
    if (!addUserCouponLoading && addUserCouponError === '') {
      setFile(null);
      refOfFileUpload.current.type = 'text';
      refOfFileUpload.current.value = '';
      refOfFileUpload.current.type = 'file';
    }
  }, [addUserCouponLoading, addUserCouponError]);

  const dobounceHandler = useCallback(
    debounce(() => {
      onSubmit();
    }, DEBOUNCE_TIME),
    [file]
  );
  const onFileChange = (value) => {
    if (CSV_REGEX.test(value?.target?.files[0]?.name)) {
      setFile(value.target.files[0]);
    } else if (value) {
      ErrorToast('Select coupon csv file');
    }
  };

  const submitClick = (e) => {
    e.preventDefault();
    dobounceHandler();
  };

  const onSubmit = () => {
    const {
      params: { couponInstanceId },
    } = updateCouponInstance;
    if (file) {
      const fileUpload = new FormData();
      fileUpload.append('csv_file', file);
      fileUpload.append('coupon_id', couponInstanceId);
      const payload = {
        fileUpload,
      };
      dispatch(CouponInstanceCreators.uploadCouponsForUsers(payload));
    } else {
      ErrorToast('Select coupon csv file', ToastPosition.TOP_CENTER, 'err-to-add');
    }
  };
  return (
    <Page>
      <AddFileContainer>
        <AddFileTop>{/* <AddFileHeading>Upload File</AddFileHeading> */}</AddFileTop>
        <AddFileFormContent
          id="file-form"
          onSubmit={(e) => {
            submitClick(e);
          }}
        >
          <AddFileLabel>
            Upload your coupons file<RequireInput>*</RequireInput>
          </AddFileLabel>
          <AddFileInput type="file" accept={'.csv'} onChange={onFileChange} ref={refOfFileUpload} />
          <AddFileButton
            type="submit"
            form="file-form"
            disabled={addUserCouponLoading}
            cursorPointer={!addUserCouponLoading}
          >
            {addUserCouponLoading ? 'Uploading...' : 'Upload'}
          </AddFileButton>
        </AddFileFormContent>
      </AddFileContainer>
    </Page>
  );
};

export default AddUserCoupons;
