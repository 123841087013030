import styled from 'styled-components';
import {
  borderSecondayColor,
  OPEN_SANS,
  LATO,
  titleTextColor,
  backgroundColor,
  textPrimaryColor,
  backgroundSecondayColor,
  buttonTextPrimaryColor,
  buttonBackgroundPrimaryColor,
  borderPrimaryColor,
  boxShadowStyle,
} from '../../constants/baseStyles';

const Page = styled.div`
  background-color: ${backgroundSecondayColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AddFileContainer = styled.div`
  margin: 5px 5px 0 5px;
  width: 99%;
  height: calc(100vh - 58px);
  border-top: 3px solid ${borderSecondayColor};
  border-radius: 5px;
  background-color: ${backgroundColor};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
`;
const AddFileTop = styled.div`
  margin: 10px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
`;
const AddFileHeading = styled.h3`
  font-size: 24px;
  color: ${titleTextColor};
  font-weight: 400;
  font-family: ${LATO};
  lint-height: 1.1;
  margin-top: 20px;
`;
const AddFileFormContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20px;
  margin-left: 50px;
`;
const AddFileLabel = styled.label`
  color: ${textPrimaryColor};
  font-size: 18px;
  margin-bottom: 10px;
`;
const RequireInput = styled.span`
  color: red;
  margin-left: 2px;
`;
const AddFileInput = styled.input`
  margin-bottom: 20px;
  border: 1px solid ${borderPrimaryColor};
  height: 30px;
  border-radius: 4px;
  padding: 4px;
  color: ${textPrimaryColor};
`;
const AddFileButton = styled.button`
  margin-top: 20px;
  align-self: flex-start;
  font-family: ${OPEN_SANS};
  text-decoration: none;
  color: ${buttonTextPrimaryColor};
  background-color: ${buttonBackgroundPrimaryColor};
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  border-radius: 4px;
  font-size: 1rem;
  height: 30px;
`;

export {
  Page,
  AddFileContainer,
  AddFileTop,
  AddFileHeading,
  AddFileInput,
  AddFileLabel,
  AddFileFormContent,
  AddFileButton,
  RequireInput,
};
