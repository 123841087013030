import validator from 'validator';
export const emailValidate = (email) => {
  return validator.isEmail(email.trim());
};
export const passwordValidate = (password) => {
  return validator.matches(password, /^[A-Za-z0-9_@.#!$]+$/);
};
export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const checkValidity = (argValue, rules) => {
  //rules is object in which you can give all your requirements like minlength,maxlength....
  const value = argValue.toString();
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.greaterThanZero) {
    isValid = value > 0 && validator.isNumeric(value) && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.minValue || rules.minValue === 0) {
    isValid = value >= rules.minValue && isValid;
  }

  if (rules.maxValue) {
    isValid = value <= rules.maxValue && isValid;
  }

  if (rules.isAlpha) {
    isValid = validator.isAlpha(value) && isValid;
  }

  if (rules.isURL) {
    isValid = validator.isURL(value) && isValid;
  }

  if (rules.isName) {
    isValid = validator.isAlpha(value.replace(/ +/g, '')) && isValid;
  }

  if (rules.isEmail) {
    isValid = validator.isEmail(value) && isValid;
  }

  if (rules.isPhone) {
    isValid = validator.isMobilePhone(value, 'en-IN') && isValid;
  }

  if (rules.isNumeric) {
    isValid = validator.isNumeric(value, /^[A-Za-z0-9_@.#&]+$/) && isValid;
  }

  if (rules.isAlphaNumeric) {
    isValid = validator.isAlphanumeric(value.replace(/ +/g, '')) && isValid;
  }

  if (rules.isAfter) {
    isValid = validator.isAfter(value) && isValid;
  }

  if (rules.isPassword) {
    isValid = validator.matches(value, /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/) && isValid;
  }
  return isValid;
};

const pow = Math.pow;
const floor = Math.floor;
const abs = Math.abs;
const log = Math.log;
const abbrev = 'KMB';

const round = (n, precision) => {
  let prec = Math.pow(10, precision);
  return Math.round(n * prec) / prec;
};

export const amountFormat = (n) => {
  let base = floor(log(abs(n)) / log(1000));
  const suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(n / pow(1000, base), 2) + suffix : `${n}`;
};
