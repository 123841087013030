import { call, put } from 'redux-saga/effects';
import { ToastPosition } from '../../../constants/baseStyles';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { setAccessTokenToHeader } from '../../../services/axios/helper';
import { setUserDataToLocal } from '../../../services/cookies/helper';
import { ErrorToast } from '../../../services/toast/toast';
import { Creators as AuthCreators } from './action';

export function* loginSaga(action) {
  try {
    yield put(AuthCreators.loginStart());
    const {
      payload: { email, password },
    } = action;
    //
    const apiDataObj = {
      email,
      password,
    };
    const response = yield call(ApiService.post, apiEndPoints.login, apiDataObj);

    if (response.status >= 200 && response.status < 300) {
      //
      const {
        data: { user_type: userType, token: authToken, email: email },
      } = response.data;
      const payload = {
        userType,
        authToken,
        email,
      };
      //write code to set AccessToken to localStorage / cookies
      yield setUserDataToLocal({
        accessToken: authToken,
        userType: userType,
        email: email,
      });
      yield setAccessTokenToHeader(authToken);
      //set auth token to axios headers
      yield put(AuthCreators.loginSuccess(payload));
    } else {
      throw response;
    }
  } catch (err) {
    //
    yield put(AuthCreators.loginFailed({ errorMessage: 'Failed' }));
    ErrorToast('Login Failed', ToastPosition.TOP_CENTER, 'error1');
  }
}
export function* logoutSaga(action) {
  try {
    yield put(AuthCreators.logoutStart());
    const {
      payload: { email, password },
    } = action;
    //
    const apiDataObj = {
      email,
      password,
    };
    const response = yield call(ApiService.post, apiEndPoints.login, apiDataObj);
    if (response.status >= 200 && response.status < 300) {
      //
      const payload = {
        userType: '',
        authToken: '',
      };
      yield put(AuthCreators.logoutSuccess(payload));
    } else {
      throw response;
    }
  } catch (err) {
    //
    yield put(AuthCreators.logoutFailed({ errorMessage: 'Failed' }));
    ErrorToast('Login Failed', ToastPosition.TOP_CENTER, 'error1');
  }
}
