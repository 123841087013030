import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  backgroundThirdColor,
  sidebarHighlight,
  borderSecondayColor,
  buttonTextPrimaryColor,
  backgroundSecondayColor,
  backgroundColor,
  boxShadowStyle,
  buttonBackgroundPrimaryColor,
  textSecondayColor,
  LATO,
  OPEN_SANS,
} from '../../constants/baseStyles';
import ODO_Logo from '../../assets/images/ODO_Logo.png';

const Page = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;
const Sidebar = styled.div`
  position: relative;
  width: 72px;
  background-color: ${backgroundThirdColor};
  height: 100vh;
`;

const SidebarTopSpacing = styled.div`
  postiton: relative;
  height: 25px;
  width: 100px;
`;

const SidebarLogoContainer = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 72px;
  boarder-bottom: 10px;
  text-align: center;
  padding: 0px 0 3px 0;
  backgound-color: ${backgroundThirdColor};
  margin-bottom: 40px;
`;

const SidebarLogo = styled.div`
  background-image: url(${ODO_Logo});
  width: 40px;
  height: 40px;
  background-size: contain;
`;

const SidebarIconsContainer = styled.div`
  margin: 10px 0;
  border-left: 2px solid ${(props) => (props.active ? borderSecondayColor : backgroundThirdColor)};
  background-color: ${(props) => (props.active ? sidebarHighlight : backgroundThirdColor)};
`;

const SidebarIconsLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  text-align: center;
  padding: 5px 0 3px 0;
  text-decoration: none;
  font-family: ${OPEN_SANS};
`;

const SidebarIcons = styled.div``;
const SidebarIconsText = styled.p`
  color: ${buttonTextPrimaryColor};
  font-size: 0.9rem;
`;

const RightBoxContent = styled.div`
  width: calc(100vw - 72px);
  height: 100vh;
  background-color: ${backgroundSecondayColor};
  overflow: hidden;
`;

const TopBar = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 72px);
  padding: 0 10px 0 10px;
  background-color: ${backgroundColor};
  box-shadow: ${boxShadowStyle};
`;

const TopBarLeftContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const BackButtonContainer = styled.div`
  display: ${(props) => (props.goBackButtonVisible ? 'block' : 'none')};
`;

const BackButton = styled.div`
  padding: 0 5px;
  cursor: pointer;
`;

const BackButtonIcon = styled.div`
  border: solid ${buttonBackgroundPrimaryColor};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
`;

const PageTitleContainer = styled.div`
  padding-left: 2px;
`;

const PageTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${textSecondayColor};
  font-family: ${LATO};
`;

const TopBarRightContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const RightMenuContainer = styled.div`
  margin-right: 5px;
  padding: 0 5px;
  cursor: pointer;
`;

const RightMenuIcon = styled.div`
  width: 6px;
  height: 20px;
  background-image: radial-gradient(circle, ${buttonBackgroundPrimaryColor} 1.5px, transparent 2px);
  background-size: 100% 33.33%;
`;

const UserNameContainer = styled.div`
  margin-right: 5px;
`;

const UserNameText = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${textSecondayColor};
  font-family: ${OPEN_SANS};
`;
const LogOutModalContainer = styled.div`
  background: red;
`;
const LogOutModal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 60px;
  font-size: 15px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  right: 15px;
  align-items: center;
  background-color: white;
  cursor: pointer;
  box-shadow: black;
  justify-content: space-around;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 2px 5px 15px 0px;
  border-top: 1px solid #b4b6b8;
  border-left: 1px solid #b4b6b8;
`;
const CarretUpIcon = styled.div`
  position: absolute;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  border-bottom: solid 10px #ffff;
  top: 49px;
  right: 22px;
`;

export {
  Page,
  Sidebar,
  SidebarLogo,
  SidebarIconsContainer,
  SidebarIconsLink,
  SidebarIcons,
  SidebarIconsText,
  SidebarTopSpacing,
  SidebarLogoContainer,
  RightBoxContent,
  TopBar,
  TopBarLeftContainer,
  BackButtonContainer,
  BackButton,
  BackButtonIcon,
  PageTitleContainer,
  PageTitle,
  TopBarRightContainer,
  RightMenuContainer,
  RightMenuIcon,
  UserNameContainer,
  UserNameText,
  LogOutModalContainer,
  CarretUpIcon,
  LogOutModal,
};
