import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  OPEN_SANS,
  textPrimaryColor,
  backgroundSecondayColor,
  buttonTextPrimaryColor,
  buttonBackgroundPrimaryColor,
  borderPrimaryColor,
  boxShadowStyle,
} from '../../constants/baseStyles';
const Page = styled.div`
  background-color: ${backgroundSecondayColor};
  display: flex;
`;
const NewUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 90.5vh;
  width: 94.1vw;
  margin-top: 5px;
  margin-left: 10px;
  border-radius: 6px;
`;
const NewUserProfile = styled.div`
  display: flex;
  flex: 1;
  color: ${textPrimaryColor};
  font-size: 18px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: white;
`;
const NewUserForm = styled.div`
  flex: 4;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: white;
`;
const NewUserFormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 55px;
  margin-left: 10px;
`;
const InputLabel = styled.label`
  color: ${textPrimaryColor};
  font-size: 18px;
  margin-bottom: 5px;
`;
const FormInput = styled.input`
  margin-bottom: 20px;
  border: 1px solid ${borderPrimaryColor};
  height: 30px;
  width: 100%;
  border-radius: 4px;
  padding: 4px;
  color: ${textPrimaryColor};
`;
const RequireInput = styled.span`
  color: red;
  margin-left: 2px;
`;

const InputFieldContainer = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -30px;
  margin-top: 5px;
`;

const InputError = styled.p`
  color: red;
  margin-top: -20px;
  margin-bottom: 20px;
`;

const AddUserButton = styled(Link)`
  // margin-left: 20px;
  margin-top: 10px;
  align-self: flex-start;
  font-family: ${OPEN_SANS};
  text-decoration: none;
  color: ${buttonTextPrimaryColor};
  background-color: ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  padding: 6px 12px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  border-radius: 4px;
  font-size: 1rem;
  height: 30px;
`;

export {
  Page,
  NewUserContainer,
  NewUserProfile,
  NewUserForm,
  NewUserFormContent,
  InputLabel,
  FormInput,
  AddUserButton,
  InputFieldContainer,
  Image,
  InputError,
  RequireInput,
};
