import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  //List Notification
  loadNotificationList: ['payload'],
  loadNotificationListStart: ['payload'],
  loadNotificationListSuccess: ['payload'],
  loadNotificationListFailure: ['payload'],
  //Add Notification
  addNotification: ['payload'],
  addNotificationStart: ['payload'],
  addNotificationSuccess: ['payload'],
  addNotificationFailure: ['payload'],
  //reset
  dataReset: ['payload'],
});

export { Types as NotificationTypes, Creators as NotificationCreators };
