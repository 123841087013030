import styled from 'styled-components';
import {
  borderSecondayColor,
  OPEN_SANS,
  LATO,
  titleTextColor,
  backgroundColor,
  textPrimaryColor,
  backgroundSecondayColor,
  buttonTextPrimaryColor,
  buttonBackgroundPrimaryColor,
  borderPrimaryColor,
  boxShadowStyle,
  sidebarHighlight,
  inputBorderRadius,
  boxShadow,
  boxBorderColor,
} from '../../constants/baseStyles';
import Modal from 'styled-react-modal';

const Page = styled.div`
  background-color: ${backgroundSecondayColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UsersContainer = styled.div`
  margin: 5px 5px 0 5px;
  width: 99%;
  height: calc(100vh - 58px);
  border-top: 3px solid ${borderSecondayColor};
  border-radius: 5px;
  background-color: ${backgroundColor};
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
`;
const UserTop = styled.div`
  margin: 10px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
`;
const UserHeading = styled.h3`
  font-size: 24px;
  color: ${titleTextColor};
  font-weight: 400;
  font-family: ${LATO};
  lint-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const UserButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: ${buttonTextPrimaryColor};
  background-color: ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  padding: 6px 12px;
  font-family: ${OPEN_SANS};
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  text-decoration: none;
  font-size: 1rem;
  height: 30px;
`;

const UserTableContainer = styled.div`
  display: flex;
  font-family: ${OPEN_SANS};
  border-collapse: collapse;
  margin-top: 20px;
`;

const UserTable = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  padding: 20px;
`;
const UserTableHead = styled.thead``;
const UserTableBody = styled.tbody``;
const UserTableLoadingData = styled.td`
  width: 100%;
  text-align: center;
  margin-top: 75px;
`;
const UserTableHeading = styled.th`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: ${textPrimaryColor};
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid ${borderPrimaryColor};
  font-family: ${OPEN_SANS};
`;
const UserTableRow = styled.tr`
  display: flex;
  width: 100%;
`;
const UserTableData = styled.td`
  display: flex;
  flex-direction: row;
  color: ${textPrimaryColor};
  border-bottom: 1px solid ${borderPrimaryColor};
  font-family: ${OPEN_SANS};
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const StyledModal = Modal.styled`
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${buttonTextPrimaryColor};
`;

const Content = styled.p`
  font-size: 1.4rem;
  color: ${textPrimaryColor};
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Button = styled.button`
  width: 100px;
  padding: 4px 9px;
  background-color: ${buttonBackgroundPrimaryColor};
  color: ${buttonTextPrimaryColor};
  border-color: ${buttonBackgroundPrimaryColor};
  font-size: 1.2rem;
  border-radius: ${inputBorderRadius};
  padding: 5px 8px;
  box-shadow: ${boxShadowStyle};
  cursor: pointer;
  &:hover {
    background-color: ${buttonBackgroundPrimaryColor};
    color: ${buttonTextPrimaryColor};
    border: 1px solid ${buttonBackgroundPrimaryColor};
  }
`;

const MenuContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(circle, ${buttonBackgroundPrimaryColor} 1.5px, transparent 2px);
  background-size: 100% 33.33%;
  z-index: 1;
  cursor: pointer;
`;

const SpecificationModal = styled.div`
  position: absolute;
  margin-top: -25px;
  margin-left: 35px;
  box-shadow: black;
  border-radius: 4px;
  box-shadow: ${boxShadow};
  border: 1px solid ${boxBorderColor};
  border-top: 0px;
`;

const SpecificationData = styled.div`
  width: 100px;
  height: 30px;
  font-size: 15px;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${titleTextColor};
  border-top: 1px solid ${boxBorderColor};
  &: hover {
    color: ${sidebarHighlight};
  }
`;

const LeftIcon = styled.div`
  position: relative;
  left: -5px;
  border-left: solid 10px transparent;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;
  border-right: solid 10px ${buttonTextPrimaryColor};
`;

export {
  Page,
  UsersContainer,
  UserHeading,
  UserTop,
  UserButton,
  UserTableContainer,
  UserTable,
  UserTableHeading,
  UserTableRow,
  UserTableData,
  UserTableHead,
  UserTableBody,
  UserTableLoadingData,
  StyledModal,
  Content,
  ButtonContainer,
  Button,
  MenuContainer,
  SpecificationModal,
  SpecificationData,
  LeftIcon,
};
