import styled from 'styled-components';
import {
  backgroundColor,
  transparentBgColor,
  borderPrimaryColor,
  borderSecondayColor,
  boxShadowStyle,
  buttonBackgroundPrimaryColor,
  buttonTextPrimaryColor,
  inputBorderRadius,
  LATO,
  OPEN_SANS,
  textPrimaryColor,
} from '../../constants/baseStyles';

const FromContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const FormTopContainer = styled.div`
  margin: 10px 20px;
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px 30px;
  cursor: pointer;
`;

const ImagePreView = styled.img`
  position: relative;
  width: 320px;
  height: 180px;
  z-index: 10;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin: 10px;
  padding: 0 25px;
`;
const HeaderRightContainer = styled.div`
  display: flex;
`;
const EditCompanyButtonContainer = styled.div`
  background-color: ${buttonBackgroundPrimaryColor};
  display: flex;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
`;
const EditCompanyButton = styled.div`
  display: flex;
  color: ${buttonTextPrimaryColor};
  font-family: ${OPEN_SANS};
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  text-decoration: none;
`;
const ImageInput = styled.input`
  display: none;
  position: absolute:
`;
const ImageContainerLabel = styled.p`
  position: absolute;
  font-size: 1rem;
  color: ${textPrimaryColor};
  font-family: ${OPEN_SANS};
  top: calc(90px - 0.5rem);
  width: 100%;
  text-align: center;
`;

const ImageContainerBottomLabel = styled.p`
  font-size: 1rem;
  color: ${textPrimaryColor};
  font-family: ${OPEN_SANS};
  text-align: center;
`;

const FormMiddleContainer = styled.div`
  margin: 10px 20px;
`;

const GeneralRow = styled.div`
  margin-top: 20px;
`;

const GeneralInputContainer = styled.div`
  display: inline-block;
  margin: 0 30px;
  position: relative;
`;

const GeneralInputLabel = styled.p`
  font-size: 1rem;
  color: ${textPrimaryColor};
  font-family: ${OPEN_SANS};
`;

const GeneralInputBox = styled.input`
  font-size: 1.2rem;
  color: ${textPrimaryColor};
  border: 1px solid ${borderPrimaryColor};
  width: 320px;
  padding: 4px 9px;
  margin-top: 8px;
  border-radius: ${inputBorderRadius};
  font-family: ${LATO};
  &:focus {
    border: 1px solid ${borderSecondayColor};
  }
`;

const FormFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.6rem 0;
`;

const SaveCompanyButtonContainer = styled.div`
  margin: 20px;
`;

const SaveCompanyButton = styled.button`
  background-color: ${buttonBackgroundPrimaryColor};
  border: 2px solid ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  display: flex;
  color: ${buttonTextPrimaryColor};
  font-family: ${OPEN_SANS};
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  text-decoration: none;
  cursor: ${(props) => (props.cursorPointer ? 'pointer' : 'none')};
`;

const CancelCompanyButtonContainer = styled.div`
  margin: 20px;
`;

const CancelCompanyButton = styled.button`
  display: flex;
  color: ${buttonBackgroundPrimaryColor};
  background-color: ${backgroundColor};
  border: 2px solid ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  margin: 20px 0;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
  font-family: ${OPEN_SANS};
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
`;

const GeneralDropdownContainer = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  flex-direction: column;
  background-color: ${backgroundColor};
  border: 2px solid ${buttonBackgroundPrimaryColor};
  border-radius: 4px;
  padding: 6px 12px;
  position: absolute;
  top: 30;
  width: 100%;
  z-index: 10;
`;
const SuggestionItem = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${OPEN_SANS};
  font-size: 1rem;
  padding: 0px 6px;
  margin: 3px 0px;
  cursor: pointer;
`;
const ImageMessage = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  font-family: ${OPEN_SANS};
  font-size: 1rem;
  padding: 0px 6px;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  text-align: center;
  align-items: center;
  background-color: ${transparentBgColor};
  color: #fff;
`;

const CouponsButtonContainer = styled.div`
  background-color: ${buttonBackgroundPrimaryColor};
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin-right: 30px;
  &:hover {
    box-shadow: ${boxShadowStyle};
  }
`;
const CouponsButton = styled.div`
  display: flex;
  color: ${buttonTextPrimaryColor};
  font-family: ${OPEN_SANS};
  align-items: center;
  padding: 6px 12px;
  font-size: 1rem;
  text-decoration: none;
`;

export {
  FromContainer,
  FormTopContainer,
  ImageContainer,
  ImagePreView,
  ImageInput,
  ImageContainerLabel,
  ImageContainerBottomLabel,
  FormMiddleContainer,
  GeneralRow,
  GeneralInputBox,
  GeneralInputContainer,
  GeneralInputLabel,
  FormFooterContainer,
  SaveCompanyButtonContainer,
  SaveCompanyButton,
  CancelCompanyButtonContainer,
  CancelCompanyButton,
  HeaderContainer,
  HeaderRightContainer,
  EditCompanyButtonContainer,
  EditCompanyButton,
  GeneralDropdownContainer,
  SuggestionItem,
  ImageMessage,
  CouponsButtonContainer,
  CouponsButton,
};
