/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';

import { Page, Container } from './Company.Styles';
import { CompanyCreators } from './store/action';

import {
  FromContainer,
  FormTopContainer,
  ImageContainer,
  ImagePreView,
  ImageInput,
  ImageContainerLabel,
  ImageContainerBottomLabel,
  FormMiddleContainer,
  GeneralInputContainer,
  GeneralInputLabel,
  GeneralInputBox,
  GeneralRow,
  FormFooterContainer,
  SaveCompanyButtonContainer,
  SaveCompanyButton,
  CancelCompanyButtonContainer,
  CancelCompanyButton,
  HeaderContainer,
  HeaderRightContainer,
  EditCompanyButtonContainer,
  EditCompanyButton,
  ImageMessage,
  CouponsButtonContainer,
  CouponsButton,
} from './AddEditCompany.Styles';
import { IMAGE_REGEX } from '../../constants/constant';
import { ErrorToast, WarningToast } from '../../services/toast/toast';
import { ToastPosition } from '../../constants/baseStyles';
import { checkValidity } from '../../constants/validation';

const AddEditCompany = () => {
  const dispatch = useDispatch();
  const selectedCompanyObj = useSelector((state) => state.company.companyDetails);
  const addCompany = useMatch('company/add');
  const navigate = useNavigate();
  const location = useLocation();

  const [company, setCompany] = useState(selectedCompanyObj);
  const [companyEdited, setCompanyEdited] = useState(false);
  const [editClicked, setEditClicked] = useState(addCompany !== null);
  const logoFile = useRef(null);

  const loadingAdd = useSelector((state) => state.company.loadingAddCompany);

  useEffect(() => {
    if (addCompany === null) {
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      dispatch(CompanyCreators.companyDetails({ companyId: id }));
    } else {
      dispatch(CompanyCreators.addCompanySuccess({}));
    }
  }, []);

  useEffect(() => {
    setCompany(selectedCompanyObj);
  }, [selectedCompanyObj]);

  const onImageUploadClick = () => {
    //
    logoFile.current.click();
  };

  // update function
  const onFieldsValueChange = (fieldName, value) => {
    const newSelectedCompany = { ...company };
    if (fieldName === 'logo') {
      //
      if (IMAGE_REGEX.test(value?.target?.files[0]?.name)) {
        //
        const objectUrl = URL.createObjectURL(value?.target?.files[0]);
        newSelectedCompany.logo.file = value?.target?.files[0];
        newSelectedCompany.logo.imageURL = objectUrl;
        setCompany(newSelectedCompany);
      } else {
        ErrorToast('Select Logo', ToastPosition.TOP_CENTER, `ERROR`);
      }
    } else if (fieldName === 'companyName') {
      newSelectedCompany[fieldName] = value.target.value;
    } else if (fieldName === 'about') {
      newSelectedCompany.about = value.target.value;
    } else if (fieldName === 'contactPersonPrimary.name') {
      newSelectedCompany.contactPersonPrimary.name = value.target.value;
    } else if (fieldName === 'contactPersonPrimary.email') {
      newSelectedCompany.contactPersonPrimary.email = value.target.value.trim();
    } else if (fieldName === 'contactPersonPrimary.contactNo') {
      newSelectedCompany.contactPersonPrimary.contactNo = value.target.value.trim();
    } else if (fieldName === 'contactPersonSecondary.name') {
      newSelectedCompany.contactPersonSecondary.name = value.target.value;
    } else if (fieldName === 'contactPersonSecondary.email') {
      newSelectedCompany.contactPersonSecondary.email = value.target.value.trim();
    } else if (fieldName === 'contactPersonSecondary.contactNo') {
      newSelectedCompany.contactPersonSecondary.contactNo = value.target.value.trim();
    } else if (fieldName === 'gst') {
      newSelectedCompany.gst = value.target.value.trim();
    }
    setCompany(newSelectedCompany);
    if (!companyEdited) {
      setCompanyEdited(true);
    }
  };
  // update function
  const onCancelClick = () => {
    if (addCompany) {
      navigate('/');
    } else {
      setEditClicked(false);
      setCompany(selectedCompanyObj);
    }
  };

  // update function
  const onSaveClick = () => {
    //
    const { logo, companyName, contactPersonPrimary, contactPersonSecondary, about, gst } = company;
    // edit mode
    if (addCompany === null) {
      let dataEdited = false;
      const filesUpdated = logo?.file;
      const index = location.pathname.lastIndexOf('/');
      const id = location.pathname.slice(index + 1);
      const fileUpload = new FormData();
      if (filesUpdated) {
        //
        fileUpload.append('logo', logo.file);
        dataEdited = true;
      }
      if (companyName !== selectedCompanyObj.companyName) {
        //
        fileUpload.append('company_name', companyName);
        dataEdited = true;
      }
      if (about !== selectedCompanyObj.about) {
        //
        fileUpload.append('about', about.trim());
        dataEdited = true;
      }
      fileUpload.append('contact_person_primary', contactPersonPrimary.name);
      fileUpload.append('contact_person_primary_email', contactPersonPrimary.email);
      fileUpload.append('contact_person_primary_phone_number', contactPersonPrimary.contactNo);
      dataEdited = true;
      fileUpload.append('contact_person_secondary', contactPersonSecondary.name);
      fileUpload.append('contact_person_secondary_email', contactPersonSecondary.email);
      fileUpload.append('contact_person_secondary_phone_number', contactPersonSecondary.contactNo);
      fileUpload.append('gst_no', gst);
      dataEdited = true;
      const payload = {
        fileUpload,
        companyId: id,
      };
      if (!dataEdited) {
        WarningToast('Data is not edited', ToastPosition.TOP_CENTER, 'data-edit-failed');
      } else {
        dispatch(CompanyCreators.editCompany(payload));
      }
    } else {
      // for add mode
      let dataMissed = true;
      const filesUpdated = logo?.file;
      if (filesUpdated) {
        //
        dataMissed = false;
      }
      if (dataMissed) {
        WarningToast('Select Company Logo', ToastPosition.TOP_CENTER, 'Add-image');
        return;
      }
      dataMissed = companyName.trim() === '';
      if (dataMissed) {
        WarningToast('Company Name', ToastPosition.TOP_CENTER, 'Add-other-lan');
        return;
      }
      dataMissed = about.trim() === '';
      if (dataMissed) {
        WarningToast('About Company', ToastPosition.TOP_CENTER, 'Add-english');
        return;
      }
      dataMissed = contactPersonPrimary.name.trim() === '';
      if (dataMissed) {
        WarningToast('Primary Contact Person Name missing', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      dataMissed =
        contactPersonPrimary.email.trim() === '' &&
        checkValidity(contactPersonPrimary.email.trim(), { isEmail: true });
      if (dataMissed) {
        WarningToast('Primary Contact Person Email missing', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      dataMissed =
        contactPersonPrimary.contactNo.trim() === '' &&
        checkValidity(contactPersonPrimary.contactNo.trim(), { isPhone: true });
      if (dataMissed) {
        WarningToast('Primary Contact Person Phone missing', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      dataMissed = contactPersonSecondary.name.trim() === '';
      if (dataMissed) {
        WarningToast('Secondary Contact Person Name missing', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      dataMissed =
        contactPersonSecondary.email.trim() === '' &&
        checkValidity(contactPersonSecondary.email.trim(), { isEmail: true });
      if (dataMissed) {
        WarningToast(
          'Secondary Contact Person Email missing',
          ToastPosition.TOP_CENTER,
          'Add-date'
        );
        return;
      }
      dataMissed =
        contactPersonSecondary.contactNo.trim() === '' &&
        checkValidity(contactPersonSecondary.contactNo.trim(), { isPhone: true });
      if (dataMissed) {
        WarningToast(
          'Secondary Contact Person Phone missing',
          ToastPosition.TOP_CENTER,
          'Add-date'
        );
        return;
      }
      dataMissed = gst.trim() === '' && checkValidity(gst.trim(), { minLength: 15, maxLength: 15 });
      if (dataMissed) {
        WarningToast('Enter correct GSTIN of length 15', ToastPosition.TOP_CENTER, 'Add-date');
        return;
      }
      const fileUpload = new FormData();
      fileUpload.append('logo', logo.file);
      fileUpload.append('company_name', companyName);
      fileUpload.append('about', about);
      fileUpload.append('contact_person_primary', contactPersonPrimary.name);
      fileUpload.append('contact_person_primary_email', contactPersonPrimary.email);
      fileUpload.append('contact_person_primary_phone_number', contactPersonPrimary.contactNo);
      fileUpload.append('contact_person_secondary', contactPersonSecondary.name);
      fileUpload.append('contact_person_secondary_email', contactPersonSecondary.email);
      fileUpload.append('contact_person_secondary_phone_number', contactPersonSecondary.contactNo);
      fileUpload.append('gst_no', gst);
      const payload = {
        fileUpload,
      };
      dispatch(CompanyCreators.addCompany(payload));
    }
    //
  };

  const editClickHandler = (editFor, payload) => {
    if (!editClicked) {
      WarningToast('Enable Edit Mode.', ToastPosition.TOP_CENTER, 'data-edit-failed');
    } else {
      if (editFor === 'logo') {
        onImageUploadClick(payload);
      } else {
        onFieldsValueChange(editFor, payload);
      }
    }
  };

  const onCouponsClick = () => {
    navigate('coupons');
  };

  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderRightContainer>
            {addCompany === null && !editClicked && (
              <CouponsButtonContainer>
                <CouponsButton onClick={onCouponsClick}>Coupons</CouponsButton>
              </CouponsButtonContainer>
            )}
            {addCompany === null && !editClicked && (
              <EditCompanyButtonContainer>
                <EditCompanyButton
                  onClick={() => {
                    setEditClicked(true);
                  }}
                >
                  Edit Organisations
                </EditCompanyButton>
              </EditCompanyButtonContainer>
            )}
          </HeaderRightContainer>
        </HeaderContainer>
        {company && (
          <FromContainer>
            <FormTopContainer>
              <ImageContainer onClick={() => editClickHandler('logo')}>
                <ImagePreView
                  src={
                    selectedCompanyObj.logo?.imageURL !== '' ? selectedCompanyObj.logo.imageURL : ''
                  }
                />
                <ImageMessage show={!addCompany === null && editClicked}>
                  Click to edit image.
                </ImageMessage>
                <ImageInput
                  type={'file'}
                  onChange={(file) => onFieldsValueChange('logo', file)}
                  ref={logoFile}
                />
                {selectedCompanyObj.logo?.imageURL === '' && (
                  <ImageContainerLabel>{'Click to add an Logo.'}</ImageContainerLabel>
                )}
                <ImageContainerBottomLabel>{'Logo'}</ImageContainerBottomLabel>
              </ImageContainer>
            </FormTopContainer>
            <FormMiddleContainer>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Company Name</GeneralInputLabel>
                  <GeneralInputBox
                    value={company.companyName}
                    onChange={(event) => editClickHandler('companyName', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>About</GeneralInputLabel>
                  <GeneralInputBox
                    value={company.about}
                    onChange={(event) => editClickHandler('about', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Primary Contact Name</GeneralInputLabel>
                  <GeneralInputBox
                    type={'text'}
                    value={company.contactPersonPrimary.name}
                    onChange={(event) => editClickHandler('contactPersonPrimary.name', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Primary Contact Email</GeneralInputLabel>
                  <GeneralInputBox
                    type={'email'}
                    value={company.contactPersonPrimary.email}
                    onChange={(event) => editClickHandler('contactPersonPrimary.email', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Primary Contact Phone</GeneralInputLabel>
                  <GeneralInputBox
                    type={'text'}
                    value={company.contactPersonPrimary.contactNo}
                    onChange={(event) => editClickHandler('contactPersonPrimary.contactNo', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>Secondary Contact Name</GeneralInputLabel>
                  <GeneralInputBox
                    type={'text'}
                    value={company.contactPersonSecondary.name}
                    onChange={(event) => editClickHandler('contactPersonSecondary.name', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Secondary Contact Email</GeneralInputLabel>
                  <GeneralInputBox
                    type={'email'}
                    value={company.contactPersonSecondary.email}
                    onChange={(event) => editClickHandler('contactPersonSecondary.email', event)}
                  />
                </GeneralInputContainer>
                <GeneralInputContainer>
                  <GeneralInputLabel>Secondary Contact Phone</GeneralInputLabel>
                  <GeneralInputBox
                    type={'text'}
                    value={company.contactPersonSecondary.contactNo}
                    onChange={(event) =>
                      editClickHandler('contactPersonSecondary.contactNo', event)
                    }
                  />
                </GeneralInputContainer>
              </GeneralRow>
              <GeneralRow>
                <GeneralInputContainer>
                  <GeneralInputLabel>GSTIN</GeneralInputLabel>
                  <GeneralInputBox
                    type={'text'}
                    value={company.gst}
                    onChange={(event) => editClickHandler('gst', event)}
                  />
                </GeneralInputContainer>
              </GeneralRow>
            </FormMiddleContainer>
            {editClicked && (
              <FormFooterContainer>
                <SaveCompanyButtonContainer>
                  <SaveCompanyButton
                    onClick={loadingAdd ? () => {} : onSaveClick}
                    loadingAdd={loadingAdd}
                    cursorPointer={true}
                  >
                    {loadingAdd ? 'Adding...' : 'Save'}
                  </SaveCompanyButton>
                </SaveCompanyButtonContainer>
                <CancelCompanyButtonContainer>
                  <CancelCompanyButton onClick={onCancelClick}>Cancel</CancelCompanyButton>
                </CancelCompanyButtonContainer>
              </FormFooterContainer>
            )}
          </FromContainer>
        )}
      </Container>
    </Page>
  );
};

export default AddEditCompany;
