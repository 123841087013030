import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  fetchCampaignList: ['payload'],
  fetchCampaignListStart: null,
  fetchCampaignListSuccess: ['payload'],
  fetchCampaignListFaliure: ['payload'],

  getCampaignDetails: ['payload'],
  getCampaignDetailsStart: null,
  getCampaignDetailsSuccess: ['payload'],
  getCampaignDetailsFaliure: ['payload'],

  addCampaign: ['payload'],
  addCampaignStart: null,
  addCampaignSuccess: ['payload'],
  addCampaignFaliure: ['payload'],
});

export { Types as CampaignsTypes, Creators as CampaignsCreators };
