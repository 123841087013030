import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { CompanyCreators } from './store/action';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../components/Table/Table.View';
import { PAGES } from '../../constants/constant';

const tableHeader = [
  {
    title: 'Organisation Name',
    key: 'company_name',
  },
  {
    title: 'Contact Person Name',
    key: 'contact_person_primary',
  },
];

const Company = () => {
  const company = useSelector((state) => state.company);
  const loadingCompanyList = company.loadingCompanyList;
  const totalPage = company.companyListTotalPage;
  const companyList = company.companyList;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    //
    dispatch(CompanyCreators.fetchCompanyList({ page_size: pageSize, pageNumber: pageNumber }));
  }, []);

  const visitDetails = (e) => {
    navigate(`details/${e}`);
  };

  const onPageChangeClick = ({ pageNumber, page_size }) => {
    //
    setPageNumber(pageNumber);
    setPageNumber(page_size);
    dispatch(CompanyCreators.fetchCompanyList({ page_size: pageSize, pageNumber: pageNumber }));
  };

  return (
    <CommonTable
      pageTitle={PAGES.ORGANISATION.title}
      addButtonText={PAGES.ORGANISATION.addButtonText}
      tableHeader={tableHeader}
      tableData={companyList}
      totalPage={totalPage}
      loading={loadingCompanyList}
      pageSize={pageSize}
      onChangePageClick={onPageChangeClick}
      addButtonNavigation={PAGES.ORGANISATION.addButtonNavigation}
      visitDetails={visitDetails}
    />
  );
};
export default Company;
