import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userDataCreators } from '../Users/store/action';
import { useNavigate } from 'react-router-dom';
import {
  Page,
  NewUserContainer,
  NewUserProfile,
  NewUserForm,
  NewUserFormContent,
  InputLabel,
  FormInput,
  AddUserButton,
  InputError,
  RequireInput,
  Image,
  InputFieldContainer,
} from './NewUser.Styles';
import { checkValidity } from '../../constants/validation';
import eye from '../../assets/images/eye.png';
import eyeclosed from '../../assets/images/eyeclosed.png';

const NewUser = () => {
  //state Management
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setpasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [nameValidated, setNameValidated] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState(false);
  const [addbuttonClicked, setAddbuttonClicked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //redux store Management
  const userAdded = useSelector((state) => state.userData);

  useEffect(() => {
    //if successfully superadmin added the new user
    if (userAdded.addUserMessage === 'Succeed') {
      navigate('/users');
    }
    //if superadmin couldn't add the new user , it resets the all state variable
    else if (userAdded.addUserMessage === 'Failed') {
      setName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setpasswordType('password');
      setConfirmPasswordType('password');
      setEmailValidated(false);
      setNameValidated(false);
      setEmailValidated(false);
      setPasswordValidated(false);
      setAddbuttonClicked(false);
    }
    //it resets the redux store
    dispatch(userDataCreators.dataReset());
  }, [userAdded.addUserMessage]);

  const emailRules = {
    required: true,
    isEmail: true,
  };
  const passwordRules = {
    required: true,
    isPassword: true,
  };
  const nameRules = {
    required: true,
    isName: false,
    minLength: 3,
  };

  //add user click handler
  const NewUserData = () => {
    let is_valid = true;
    setAddbuttonClicked(true);
    if (checkValidity(name, nameRules)) {
      setNameValidated(true);
    } else {
      is_valid = false;
    }
    if (checkValidity(email, emailRules)) {
      setEmailValidated(true);
    } else {
      is_valid = false;
    }
    if (checkValidity(password, passwordRules)) {
      setPasswordValidated(true);
    } else {
      is_valid = false;
    }

    // password match check
    is_valid = is_valid && password === confirmPassword;

    // dispatch(userDataCreators.login(payload));
    if (is_valid) {
      const result = name.trim().split(/\s+/);
      const payload = {
        first_name: result[0],
        last_name: result[1] ? result[1] : '',
        email: email,
        password1: password,
        password2: confirmPassword,
      };
      dispatch(userDataCreators.addUserData(payload));
    }
  };

  //it changes password field from text to password and password to text
  const onPasswordToggle = (event) => {
    if (passwordType === 'password') {
      setpasswordType('text');
    } else {
      setpasswordType('password');
    }
  };

  //it changes confirm password field from text to password and password to text
  const onConfirmPasswordToggle = (event) => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text');
    } else {
      setConfirmPasswordType('password');
    }
  };

  return (
    <Page>
      <NewUserContainer>
        <NewUserProfile>Add New User</NewUserProfile>
        <NewUserForm>
          <NewUserFormContent>
            <InputLabel>
              Name<RequireInput>*</RequireInput>
            </InputLabel>
            <FormInput
              value={name}
              onChange={(username) => {
                setName(username.target.value);
              }}
            />
            {addbuttonClicked && !nameValidated && <InputError>Name is not valid</InputError>}
            <InputLabel>
              Email<RequireInput>*</RequireInput>
            </InputLabel>
            <FormInput
              value={email}
              onChange={(email) => {
                setEmail(email.target.value);
              }}
            />
            {addbuttonClicked && !emailValidated && <InputError>Email is not valid</InputError>}
            <InputLabel>
              Password<RequireInput>*</RequireInput>
            </InputLabel>
            <InputFieldContainer>
              <FormInput
                value={password}
                type={passwordType}
                onChange={(password) => {
                  setPassword(password.target.value);
                }}
              />
              {passwordType === 'password' ? (
                <Image src={eyeclosed} onClick={onPasswordToggle} />
              ) : (
                <Image src={eye} onClick={onPasswordToggle} />
              )}
            </InputFieldContainer>
            {addbuttonClicked && !passwordValidated && (
              <InputError>Password must include one special character(!@#$%^&*)</InputError>
            )}
            <InputLabel>
              Confirm Password<RequireInput>*</RequireInput>
            </InputLabel>
            <InputFieldContainer>
              <FormInput
                value={confirmPassword}
                type={confirmPasswordType}
                onChange={(password) => {
                  setConfirmPassword(password.target.value);
                }}
              />
              {confirmPasswordType === 'password' ? (
                <Image src={eyeclosed} onClick={onConfirmPasswordToggle} />
              ) : (
                <Image src={eye} onClick={onConfirmPasswordToggle} />
              )}
            </InputFieldContainer>
            {passwordValidated && password !== confirmPassword && (
              <InputError>Please make sure your password match</InputError>
            )}
            <AddUserButton
              onClick={() => {
                NewUserData();
              }}
            >
              {userAdded.loading ? `Adding...` : `Add User`}
            </AddUserButton>
          </NewUserFormContent>
        </NewUserForm>
      </NewUserContainer>
    </Page>
  );
};
export default NewUser;
