import { useEffect, useState } from 'react';
import { Paginationcontainer, PaginationNumber } from './Pagination.Styles';
import { userDataCreators } from '../../pages/Users/store/action';
import { useDispatch, useSelector } from 'react-redux';
import { BioScopeCreators } from '../../pages/BioScope/store/action';
import { NotificationCreators } from '../../pages/Notification/store/action';

const Pagination = ({ total_pages, pagesFor, page_size, onChangePageClick = null }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageClicked, setPageClicked] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userData);

  //After deleted the user , it will reset the state variables
  useEffect(() => {
    if (
      user.deleteUserMessage === 'Succeed' ||
      user.deactivateUserMessage === 'Succeed' ||
      user.activateUserMessage === 'Succeed'
    ) {
      setPageNumber(1);
    }
  }, [user.deleteUserMessage, user.deactivateUserMessage, user.activateUserMessage]);

  var pagesArray = Array(total_pages)
    .fill()
    .map((v, i) => i + 1);
  const onPageClick = (id, number) => {
    setPageNumber(number);
    const payload = {
      number: number,
    };

    setPageNumber(number);
    setPageClicked(id);
  };
  useEffect(() => {
    if (pagesFor === 'users') {
      dispatch(userDataCreators.loadUserData({ pageNumber, page_size }));
    }
    if (pagesFor === 'bioscopes') {
      dispatch(BioScopeCreators.fetchBioScopeList({ pageNumber, page_size }));
    }
    if (pagesFor === 'notification') {
      dispatch(NotificationCreators.loadNotificationList({ pageNumber, page_size }));
    }
    if (onChangePageClick) {
      onChangePageClick({ pageNumber, page_size });
    }
  }, [pageNumber, page_size]);

  return (
    <Paginationcontainer>
      {pagesArray.map((number, id) => {
        return (
          <PaginationNumber
            key={id.toString()}
            onClick={() => {
              onPageClick(id, number);
            }}
            set={number === pageNumber}
          >
            {number}
          </PaginationNumber>
        );
      })}
    </Paginationcontainer>
  );
};
export default Pagination;
