import { createActions } from 'reduxsauce';
const { Types, Creators } = createActions({
  loadUserData: ['payload'],
  loadUserDataStart: ['payload'],
  loadUserDataSuccess: ['payload'],
  loadUserDataFailure: ['payload'],
  addUserData: ['payload'],
  addUserDataStart: ['payload'],
  addUserDataSuccess: ['payload'],
  addUserDataFailure: ['payload'],
  deleteUserData: ['payload'],
  deleteUserDataStart: ['payload'],
  deleteUserDataSuccess: ['payload'],
  deleteUserDataFailure: ['payload'],
  deactivate: ['payload'],
  deactivateUserDataStart: ['payload'],
  deactivateUserDataSuccess: ['payload'],
  deactivateUserDataFailure: ['payload'],
  activate: ['payload'],
  activateUserDataStart: ['payload'],
  activateUserDataSuccess: ['payload'],
  activateUserDataFailure: ['payload'],
  dataReset: ['payload'],
});

export { Types as userDataTypes, Creators as userDataCreators };
