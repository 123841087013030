import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationCreators } from './store/action';
import { checkValidity } from '../../constants/validation';
import {
  Page,
  NewNotificationContainer,
  NewNotificationProfile,
  NewNotificationForm,
  NewNotificationFormContent,
  InputLabel,
  FormInput,
  Textarea,
  RequireInput,
  InputError,
  SendNotificationButton,
} from './AddNotification.Styles';

const AddNotification = () => {
  //state Management
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [titleValidation, setTitleValidation] = useState(false);
  const [messageValidation, setMessageValidation] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  //redux store management
  const notification = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //if successfully added the new notification
    if (notification.addNotificationMessage === 'Succeed') {
      navigate('/notification');
    }
    //if couldn't add the new notification , it resets the all state variable
    else if (notification.addNotificationMessage === 'Failed') {
      setTitle('');
      setMessage('');
      setTitleValidation(false);
      setMessageValidation(false);
      setSubmitButtonClicked(false);
    }
    //it resets the redux store
    dispatch(NotificationCreators.dataReset());
  }, [notification.addNotificationMessage]);

  const titleRules = {
    required: true,
  };
  const messageRules = {
    required: true,
  };

  const addingNotification = () => {
    let isValid = true;
    setSubmitButtonClicked(true);
    if (checkValidity(title, titleRules)) {
      setTitleValidation(true);
    } else {
      isValid = false;
    }
    if (checkValidity(message, messageRules)) {
      setMessageValidation(true);
    } else {
      isValid = false;
    }

    if (isValid) {
      const payload = {
        title: title,
        message: message,
      };
      dispatch(NotificationCreators.addNotification(payload));
    }
  };

  return (
    <Page>
      <NewNotificationContainer>
        <NewNotificationProfile>Add New Notification</NewNotificationProfile>
        <NewNotificationForm>
          <NewNotificationFormContent>
            <InputLabel>
              Title<RequireInput>*</RequireInput>
            </InputLabel>
            <FormInput
              maxLength={65}
              value={title}
              onChange={(title) => {
                setTitle(title.target.value);
              }}
            />
            {submitButtonClicked && !titleValidation && <InputError>Title is not valid</InputError>}
            <InputLabel>
              Message<RequireInput>*</RequireInput>
            </InputLabel>
            <Textarea
              maxLength={250}
              value={message}
              onChange={(message) => {
                setMessage(message.target.value);
              }}
            />
            {submitButtonClicked && !messageValidation && (
              <InputError>Message is not valid</InputError>
            )}
            <SendNotificationButton
              onClick={() => {
                addingNotification();
              }}
            >
              {notification.loading ? `Sending...` : `Send Notification`}
            </SendNotificationButton>
          </NewNotificationFormContent>
        </NewNotificationForm>
      </NewNotificationContainer>
    </Page>
  );
};
export default AddNotification;
