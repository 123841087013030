import { put, call } from 'redux-saga/effects';
import { CompanyCreators } from './action';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { ErrorToast, SuccessToast } from '../../../services/toast/toast';
import { ToastPosition } from '../../../constants/baseStyles';

export function* fetchCompanyListSaga(action) {
  const { pageNumber, page_size } = action.payload;
  try {
    yield put(CompanyCreators.fetchCompanyListStart());
    let response;
    response = yield call(ApiService.get, apiEndPoints.listOrginasation, {
      params: {
        page: pageNumber,
        page_size: page_size,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { total_pages, data } = response.data;
      const payload = {
        total_pages,
        data,
      };
      yield put(CompanyCreators.fetchCompanyListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(CompanyCreators.fetchCompanyListFailure({ errorMessage: 'Failed' }));
  }
}

export function* companyDetailsSaga(action) {
  try {
    const {
      payload: { companyId },
    } = action;
    yield put(CompanyCreators.companyDetailsStart());
    const response = yield call(ApiService.get, `${apiEndPoints.detailsOrginasation}${companyId}/`);
    if (response.status >= 200 && response.status < 300) {
      const {
        data: { data },
      } = response;
      if (data) {
        const companyDetails = {
          logo: {
            id: '1',
            label: `Logo`,
            file: null,
            imageURL: data.logo,
            name: 'Logo',
            index: 0,
          },
          companyName: data.company_name,
          contactPersonPrimary: {
            name: data.contact_person_primary,
            contactNo: data.contact_person_primary_phone_number || '',
            email: data.contact_person_primary_email || '',
          },
          contactPersonSecondary: {
            name: data.contact_person_secondary,
            contactNo: data.contact_person_secondary_phone_number || '',
            email: data.contact_person_secondary_email || '',
          },
          about: data.about,
          gst: data.gst,
        };
        yield put(CompanyCreators.companyDetailsSuccess({ companyDetails }));
      } else {
        throw response;
      }
    } else {
      throw response;
    }
  } catch (error) {
    ErrorToast('Failed to get company data', ToastPosition.TOP_CENTER, 'err-to-get-company');
    yield put(CompanyCreators.companyDetailsFailure({ errorMessage: 'Failed' }));
  }
}

export function* addCompanySaga(action) {
  try {
    yield put(CompanyCreators.addCompanyStart());
    const {
      payload: { fileUpload },
    } = action;

    const response = yield call(ApiService.post, apiEndPoints.addOrginasation, fileUpload);
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Orginasation Added', ToastPosition.TOP_CENTER, 'success-to-add');
      yield put(CompanyCreators.addCompanySuccess({}));
    } else {
      throw response;
    }
  } catch (err) {
    ErrorToast('Failed to add Orginasation', ToastPosition.TOP_CENTER, 'err-to-add');
    yield put(CompanyCreators.addCompanyFailure({ errorMessage: 'Failed' }));
  }
}

export function* editCompanySaga(action) {
  try {
    yield put(CompanyCreators.editCompanyStart());
    const {
      payload: { fileUpload, companyId },
    } = action;

    const response = yield call(
      ApiService.post,
      `${apiEndPoints.updateOrginasation}${companyId}/update/`,
      fileUpload
    );
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Orginasation edited', ToastPosition.TOP_CENTER, 'success-to-edit');
      yield put(CompanyCreators.editCompanySuccess({}));
      yield put(CompanyCreators.companyDetails({ companyId }));
    } else {
      throw response;
    }
  } catch (err) {
    ErrorToast('Failed to edit Orginasation', ToastPosition.TOP_CENTER, 'err-to-edit');
    yield put(CompanyCreators.editCompanyFailure({ errorMessage: 'Failed' }));
  }
}
