const backgroundColor = '#FFFFFF';
const transparentBgColor = 'rgba(0.8, 0.8, 0.8, 0.25)';
const backgroundSecondayColor = '#edf0f7';
const backgroundThirdColor = '#034e6e';
const buttonBackgroundPrimaryColor = '#034e6e';
const buttonTextPrimaryColor = '#FFFFFF';
const borderPrimaryColor = '#cbcbcb';
const borderSecondayColor = '#3db049';
const textPrimaryColor = '#596376';
const textSecondayColor = '#034e6e';
const sidebarHighlight = '#05374c';
const titleTextColor = '#595959';
const boxBorderColor = '#b4b6b8';

const inputBorderRadius = '3px';

const boxShadowStyle = '5px 5px 10px rgba(0, 0, 0, 0.25)';
const boxShadow = 'rgba(0, 0, 0, 0.35) 2px 5px 15px 0px';

const LATO = `'Lato', sans-serif`;
const OPEN_SANS = `'Open Sans', sans-serif`;

const ToastPosition = {
  TOP_CENTER: 'top-center',
};

export {
  backgroundColor,
  transparentBgColor,
  backgroundSecondayColor,
  backgroundThirdColor,
  buttonBackgroundPrimaryColor,
  buttonTextPrimaryColor,
  borderPrimaryColor,
  borderSecondayColor,
  textPrimaryColor,
  textSecondayColor,
  titleTextColor,
  boxBorderColor,
  sidebarHighlight,
  boxShadowStyle,
  boxShadow,
  inputBorderRadius,
  ToastPosition,
  LATO,
  OPEN_SANS,
};
