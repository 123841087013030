import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination.View';
import { NotificationCreators } from './store/action';
import { PlusIcon } from '../../constants/globalStyles.Styles';
import {
  Page,
  NotificationsContainer,
  NotificationTop,
  NotificationButton,
  NotificationHeading,
  NotificationTableContainer,
  NotificationInnerTableContainer,
  NotificationTable,
  NotificationTableHeading,
  NotificationTableRow,
  NotificationTableData,
  NotificationTableHead,
  NotificationTableBody,
  NotificationTableLoadingData,
} from './Notification.Styles';

const Notification = () => {
  //redux store management
  const notification = useSelector((state) => state.notification);
  const pushNotifications = notification.pushNotifications;
  const totalPages = notification.totalPages;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //In Initial loading it loads the user data
  useEffect(() => {
    dispatch(NotificationCreators.loadNotificationList({ pageNumber: 1 }));
  }, []);

  const addNotificationPage = () => {
    navigate('/notification/add');
  };

  return (
    <Page>
      <NotificationsContainer>
        <NotificationTop>
          <NotificationHeading>Notification</NotificationHeading>
          <NotificationButton onClick={addNotificationPage}>
            <PlusIcon></PlusIcon>
            Add Notification
          </NotificationButton>
        </NotificationTop>
        <NotificationTableContainer>
          <NotificationInnerTableContainer>
            <NotificationTable>
              <NotificationTableHead>
                <NotificationTableRow>
                  <NotificationTableHeading>Title</NotificationTableHeading>
                  <NotificationTableHeading>Message</NotificationTableHeading>
                  <NotificationTableHeading>Date</NotificationTableHeading>
                  <NotificationTableHeading>Created by</NotificationTableHeading>
                </NotificationTableRow>
              </NotificationTableHead>

              {notification.loading ? (
                <NotificationTableBody>
                  <NotificationTableRow>
                    <NotificationTableLoadingData>Loading...</NotificationTableLoadingData>
                  </NotificationTableRow>
                </NotificationTableBody>
              ) : (
                <NotificationTableBody>
                  {pushNotifications.map((item, id) => {
                    return (
                      <NotificationTableRow key={id.toString()}>
                        <NotificationTableData>{item.title}</NotificationTableData>
                        <NotificationTableData>{item.body}</NotificationTableData>
                        <NotificationTableData>{item.created_at}</NotificationTableData>
                        <NotificationTableData>{item.created_by}</NotificationTableData>
                      </NotificationTableRow>
                    );
                  })}
                </NotificationTableBody>
              )}
            </NotificationTable>
          </NotificationInnerTableContainer>
        </NotificationTableContainer>

        <Pagination total_pages={totalPages} pagesFor={'notification'} page_size={0}></Pagination>
      </NotificationsContainer>
    </Page>
  );
};

export default Notification;
