import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // GET Bio Scope List actions
  fetchBioScopeList: ['payload'],
  fetchBioScopeListStart: null,
  fetchBioScopeListSuccess: ['payload'],
  fetchBioScopeListFailure: ['payload'],
  //  Bio Scope Details actions
  bioScopeDetails: ['payload'],
  bioScopeDetailsStart: null,
  bioScopeDetailsSuccess: ['payload'],
  bioScopeDetailsFailure: ['payload'],
  // Add Bio Scope  actions
  addBioScope: ['payload'],
  addBioScopeStart: null,
  addBioScopeSuccess: ['payload'],
  addBioScopeFailure: ['payload'],
  // Edit Bio Scope  actions
  editBioScope: ['payload'],
  editBioScopeStart: null,
  editBioScopeSuccess: ['payload'],
  editBioScopeFailure: ['payload'],
  // Approve/Reject Bio Scope  actions
  approveRejectBioScope: ['payload'],
  approveRejectBioScopeStart: null,
  approveRejectBioScopeSuccess: ['payload'],
  approveRejectBioScopeFailure: ['payload'],
  // Movie Suggestion List
  bioscopeMovieSuggestion: ['payload'],
  bioscopeMovieSuggestionStart: ['payload'],
  bioscopeMovieSuggestionSuccess: ['payload'],
  bioscopeMovieSuggestionFailure: ['payload'],
});

export { Types as BioScopeTypes, Creators as BioScopeCreators };
