import styled from 'styled-components';
import {
  backgroundColor,
  borderPrimaryColor,
  textPrimaryColor,
  textSecondayColor,
  inputBorderRadius,
  borderSecondayColor,
  buttonBackgroundPrimaryColor,
  buttonTextPrimaryColor,
  boxShadowStyle,
} from '../../constants/baseStyles';

const Page = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background: ${backgroundColor};
`;

const LoginLeftContainer = styled.div`
  width: 60vw;
  height: 100vh;
  @media screen and (max-width: 786px) {
    display: none;
  }
`;

const LoginLeftVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoginRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 100vh;
  @media screen and (max-width: 786px) {
    width: 100vw;
  }
`;

const LoginFormContainer = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 400px;
`;

const LoginText = styled.p`
  color: ${textSecondayColor};
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin-bottom: 4vh;
`;

const LoginInputBoxContainer = styled.div`
  margin: 8px 0;
`;

const LoginInputBox = styled.input`
  font-size: 1.2rem;
  color: ${textPrimaryColor};
  border: 1px solid ${borderPrimaryColor};
  width: 320px;
  padding: 4px 9px;
  border-radius: ${inputBorderRadius};
  &:focus {
    border: 1px solid ${borderSecondayColor};
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -30px;
  margin-top: 5px;
`;

const LoginButtonContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 0.75px solid ${borderPrimaryColor};
`;

const LoginButton = styled.button`
  width: 320px;
  padding: 4px 9px;
  background-color: ${buttonBackgroundPrimaryColor};
  color: ${buttonTextPrimaryColor};
  border-color: ${buttonBackgroundPrimaryColor};
  font-size: 1.2rem;
  border-radius: ${inputBorderRadius};
  padding: 5px 8px;
  box-shadow: ${boxShadowStyle};
  cursor: pointer;
  &:hover {
    background-color: ${buttonBackgroundPrimaryColor};
    color: ${buttonTextPrimaryColor};
    border: 1px solid ${buttonBackgroundPrimaryColor};
  }
`;

export {
  Page,
  LoginLeftContainer,
  LoginLeftVideo,
  LoginRightContainer,
  LoginFormContainer,
  LoginText,
  LoginInputBox,
  LoginInputBoxContainer,
  Image,
  LoginButtonContainer,
  LoginButton,
};
