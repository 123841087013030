import { PlusIcon } from '../../constants/globalStyles.Styles';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { BioScopeCreators } from './store/action';
import {
  Page,
  Container,
  HeaderContainer,
  HeaderLeftContainer,
  HeaderTitleContainer,
  HeaderTitle,
  HeaderRightContainer,
  BioScopeTableContainer,
  BioScopeTable,
  BioScopeThead,
  BioScopeTH,
  BioScopeTBoddy,
  BioScopeTR,
  BioScopeTD,
  ApproveButton,
  BioScopeImage,
  DataNotAvailable,
} from './BioScope.Styles';
import { useNavigate } from 'react-router-dom';

const BioScope = () => {
  const bioSopeList = useSelector((state) => state.bioScope);
  const allbioScopes = bioSopeList.bioScopeList;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const visitDetails = (e) => {
    navigate(`details/${e}`);
  };
  useEffect(() => {
    dispatch(BioScopeCreators.fetchBioScopeList({ pageNumber: 1, pageSize }));
  }, []);

  {
    allbioScopes.map((eachBioScopeArray) => {
      eachBioScopeArray.frames.map((item) => {});
    });
  }
  return (
    <Page>
      <Container>
        <HeaderContainer>
          <HeaderLeftContainer>
            <HeaderTitleContainer>
              <HeaderTitle>Approve Bio Scope</HeaderTitle>
            </HeaderTitleContainer>
          </HeaderLeftContainer>
          <HeaderRightContainer></HeaderRightContainer>
        </HeaderContainer>
        {allbioScopes.length ? (
          <BioScopeTableContainer>
            <BioScopeTable>
              <BioScopeThead>
                <BioScopeTR>
                  <BioScopeTH>NAME</BioScopeTH>
                  <BioScopeTH>ADDED DATE</BioScopeTH>
                  <BioScopeTH>ADDED BY</BioScopeTH>
                  <BioScopeTH>ACTION</BioScopeTH>
                </BioScopeTR>
              </BioScopeThead>
              <BioScopeTBoddy>
                {allbioScopes.map((bioScope, id) => {
                  return (
                    !bioScope.approved && (
                      <BioScopeTR key={id.toString()} onClick={() => visitDetails(bioScope.id)}>
                        <BioScopeTD>{bioScope.answer.name}</BioScopeTD>
                        <BioScopeTD>{bioScope.date}</BioScopeTD>
                        <BioScopeTD> {bioScope.added_by} </BioScopeTD>
                        <BioScopeTD> To be Approved </BioScopeTD>
                      </BioScopeTR>
                    )
                  );
                })}
              </BioScopeTBoddy>
            </BioScopeTable>
          </BioScopeTableContainer>
        ) : (
          <DataNotAvailable>Nothing to Approve</DataNotAvailable>
        )}
      </Container>
    </Page>
  );
};
export default BioScope;
