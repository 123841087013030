/**
 * reducer.js for Bio Scope
 *
 * Author
 *  1. Sourabh Kumar
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { COMPANY_DETAILS_OBJ } from '../../../constants/constant';
import { CompanyTypes } from './action';

const INITIAL_STATE = {
  loadingCompanyList: false,
  loadingCompanyDetails: false,
  loadingAddCompany: false,
  loadingApproveCompany: false,
  companyList: [],
  companyListTotalPage: 1,
  companyListCurrentPage: 1,
  loading: false,
  companyDetails: { ...COMPANY_DETAILS_OBJ },
};

const fetchCompanyListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCompanyList: true,
});

const fetchCompanyListSuccess = (state = INITIAL_STATE, action) => {
  const { data, total_pages } = action.payload;
  return {
    ...state,
    loadingCompanyList: false,
    companyList: data,
    companyListTotalPage: total_pages,
  };
};

const fetchCompanyListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCompanyList: false,
});

const addCompanyStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCompany: true,
});

const addCompanySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddCompany: false,
    companyDetails: { ...COMPANY_DETAILS_OBJ },
  };
};

const addCompanyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCompany: false,
});

const companyDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCompanyDetails: true,
});

const companyDetailsSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { companyDetails },
  } = action;
  return {
    ...state,
    loadingCompanyDetails: false,
    companyDetails: companyDetails,
  };
};

const companyDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCompanyDetails: false,
});

const editCompanyStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCompany: true,
});

const editCompanySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddCompany: false,
  };
};

const editCompanyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCompany: false,
});

const HANDLERS = {
  [CompanyTypes.FETCH_COMPANY_LIST_START]: fetchCompanyListStart,
  [CompanyTypes.FETCH_COMPANY_LIST_SUCCESS]: fetchCompanyListSuccess,
  [CompanyTypes.FETCH_COMPANY_LIST_FAILURE]: fetchCompanyListFailure,

  [CompanyTypes.ADD_COMPANY_START]: addCompanyStart,
  [CompanyTypes.ADD_COMPANY_SUCCESS]: addCompanySuccess,
  [CompanyTypes.ADD_COMPANY_FAILURE]: addCompanyFailure,

  [CompanyTypes.EDIT_COMPANY_START]: editCompanyStart,
  [CompanyTypes.EDIT_COMPANY_SUCCESS]: editCompanySuccess,
  [CompanyTypes.EDIT_COMPANY_FAILURE]: editCompanyFailure,

  [CompanyTypes.COMPANY_DETAILS_START]: companyDetailsStart,
  [CompanyTypes.COMPANY_DETAILS_SUCCESS]: companyDetailsSuccess,
  [CompanyTypes.COMPANY_DETAILS_FAILURE]: companyDetailsFailure,
};

export const companyReducer = createReducer(INITIAL_STATE, HANDLERS);
