/**
 * reducer.js for Auth
 *
 * Author
 *  1. Sourabh Kumar
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { Types } from './action';
const INITIAL_STATE = {
  loginLoading: false,
  loggedin: false,
  errorMessage: '',
  userType: '',
  authToken: '',
};

// will trigger when called from login saga.
// it will updating the login loading indicator login action
const loginStart = (state = INITIAL_STATE) => ({
  ...state,
  loginLoading: true,
});

// will trigger when called from login saga and on app initialzation(if token exists in cookies).
// it will set the auth state and then user can use the application
const loginSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { userType, authToken },
  } = action;
  return {
    ...state,
    loginLoading: false,
    errorMessage: '',
    loggedin: true,
    userType,
    authToken,
  };
};

// then login is failed.
const loginFailed = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loginLoading: false,
    errorMessage,
  };
};

// will trigger when called from logout saga.
// it will updating the login loading indicator for logout action
const logoutStart = (state = INITIAL_STATE) => ({
  ...state,
  loginLoading: true,
});

// will trigger when called from logout saga or token expires condition.
// it will clear the auth state and then user cannot use the application.
const logoutSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { userType, authToken },
  } = action;
  return {
    ...state,
    loginLoading: false,
    errorMessage: '',
    loggedin: false,
    userType,
    authToken,
  };
};

// for logout is failed
const logoutFailed = (state = INITIAL_STATE, action) => {
  const {
    payload: { errorMessage },
  } = action;
  return {
    ...state,
    loginLoading: false,
    errorMessage,
  };
};

//Maping the action types with their functions which will update the store
const HANDLERS = {
  [Types.LOGIN_START]: loginStart,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILED]: loginFailed,
  [Types.LOGOUT_START]: logoutStart,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILED]: logoutFailed,
};

export const authReducer = createReducer(INITIAL_STATE, HANDLERS);
