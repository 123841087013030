/**
 * reducer.js for Bio Scope
 *
 * Author
 *  1. Sourabh Kumar
 *
 * @copyright of Panorbit Services LLP.
 */

import { createReducer } from 'reduxsauce';
import { COUPON_INSTANCE_OBJ } from '../../../constants/constant';
import { CouponInstanceTypes } from './action';

const INITIAL_STATE = {
  loadingCouponInstanceList: false,
  loadingCouponInstanceDetails: false,
  loadingAddCouponInstance: false,
  loadingApproveCouponInstance: false,
  couponInstanceList: [],
  couponInstanceListTotalPage: 1,
  couponInstanceListCurrentPage: 1,
  loading: false,
  couponInstanceDetails: { ...COUPON_INSTANCE_OBJ },
  addUserCouponLoading: false,
  addUserCouponError: '',
};

const fetchCouponInstanceListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCouponInstanceList: true,
});

const fetchCouponInstanceListSuccess = (state = INITIAL_STATE, action) => {
  const { data, total_pages } = action.payload;
  return {
    ...state,
    loadingCouponInstanceList: false,
    couponInstanceList: data,
    couponInstanceListTotalPage: total_pages,
  };
};

const fetchCouponInstanceListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCouponInstanceList: false,
});

const addCouponInstanceStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCouponInstance: true,
});

const addCouponInstanceSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddCouponInstance: false,
    couponInstanceDetails: { ...COUPON_INSTANCE_OBJ },
  };
};

const addCouponInstanceFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCouponInstance: false,
});

const couponInstanceDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCouponInstanceDetails: true,
});

const couponInstanceDetailsSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { couponInstanceDetails },
  } = action;
  return {
    ...state,
    loadingCouponInstanceDetails: false,
    couponInstanceDetails: couponInstanceDetails,
  };
};

const couponInstanceDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCouponInstanceDetails: false,
});

const editCouponInstanceStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCouponInstance: true,
});

const editCouponInstanceSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddCouponInstance: false,
  };
};

const editCouponInstanceFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCouponInstance: false,
});

const uploadCouponsForUsersStart = (state = INITIAL_STATE, action) => ({
  ...state,
  addUserCouponLoading: true,
  addUserCouponError: '',
});

const uploadCouponsForUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addUserCouponLoading: false,
  };
};

const uploadCouponsForUsersFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  addUserCouponLoading: false,
  addUserCouponError: action.payload.errorMessage,
});

const HANDLERS = {
  [CouponInstanceTypes.FETCH_COUPON_INSTANCE_LIST_START]: fetchCouponInstanceListStart,
  [CouponInstanceTypes.FETCH_COUPON_INSTANCE_LIST_SUCCESS]: fetchCouponInstanceListSuccess,
  [CouponInstanceTypes.FETCH_COUPON_INSTANCE_LIST_FAILURE]: fetchCouponInstanceListFailure,

  [CouponInstanceTypes.ADD_COUPON_INSTANCE_START]: addCouponInstanceStart,
  [CouponInstanceTypes.ADD_COUPON_INSTANCE_SUCCESS]: addCouponInstanceSuccess,
  [CouponInstanceTypes.ADD_COUPON_INSTANCE_FAILURE]: addCouponInstanceFailure,

  [CouponInstanceTypes.EDIT_COUPON_INSTANCE_START]: editCouponInstanceStart,
  [CouponInstanceTypes.EDIT_COUPON_INSTANCE_SUCCESS]: editCouponInstanceSuccess,
  [CouponInstanceTypes.EDIT_COUPON_INSTANCE_FAILURE]: editCouponInstanceFailure,

  [CouponInstanceTypes.COUPON_INSTANCE_DETAILS_START]: couponInstanceDetailsStart,
  [CouponInstanceTypes.COUPON_INSTANCE_DETAILS_SUCCESS]: couponInstanceDetailsSuccess,
  [CouponInstanceTypes.COUPON_INSTANCE_DETAILS_FAILURE]: couponInstanceDetailsFailure,

  [CouponInstanceTypes.UPLOAD_COUPONS_FOR_USERS_START]: uploadCouponsForUsersStart,
  [CouponInstanceTypes.UPLOAD_COUPONS_FOR_USERS_SUCCESS]: uploadCouponsForUsersSuccess,
  [CouponInstanceTypes.UPLOAD_COUPONS_FOR_USERS_FAILURE]: uploadCouponsForUsersFailure,
};

export const couponInstanceReducer = createReducer(INITIAL_STATE, HANDLERS);
