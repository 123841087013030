import { put, call } from 'redux-saga/effects';
import { userDataCreators } from './action';
import { ToastPosition } from '../../../constants/baseStyles';
import { ErrorToast, SuccessToast } from '../../../services/toast/toast';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';

export function* loadUserSaga(action) {
  // const number = action.payload.number;
  try {
    yield put(userDataCreators.loadUserDataStart());
    let response;
    if (action.payload.pageNumber > 1) {
      response = yield call(ApiService.get, apiEndPoints.userList, {
        params: {
          page: action.payload.pageNumber,
        },
      });
    } else {
      response = yield call(ApiService.get, apiEndPoints.userList, {
        params: {
          page: 1,
        },
      });
    }
    if (response.status >= 200 && response.status < 300) {
      //
      const { users, total_pages } = response.data.data;
      const payload = {
        users,
        total_pages,
      };
      yield put(userDataCreators.loadUserDataSuccess(payload));
      //send the payload to reducer to update the state
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);
    yield put(userDataCreators.loadUserDataFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}
export function* addUserSaga(action) {
  try {
    yield put(userDataCreators.addUserDataStart());
    const {
      payload: { first_name, last_name, email, password1, password2 },
    } = action;
    //
    const apiDataObj = {
      first_name,
      last_name,
      email,
      password1,
      password2,
    };
    const response = yield call(ApiService.post, apiEndPoints.addUser, apiDataObj);
    if (response.status >= 200 && response.status < 300) {
      const { users, total_pages } = response.data.data;
      const payload = {
        users,
        total_pages,
      };
      yield put(userDataCreators.addUserDataSuccess(payload));
      SuccessToast('Successfully Added New User', ToastPosition.TOP_CENTER, 'success1');
      //send the payload to reducer to update the state
    } else {
      throw response;
    }
  } catch (error) {
    yield put(userDataCreators.addUserDataFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}

export function* deleteUserSaga(action) {
  try {
    yield put(userDataCreators.deleteUserDataStart());
    const { id, navigate } = action.payload;

    let url = `${apiEndPoints.deleteUser}${id}/delete/`;

    const response = yield call(ApiService.delete, url, {});

    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Successfully Deleted', ToastPosition.TOP_CENTER, 'success1');
      yield put(userDataCreators.deleteUserDataSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    yield put(userDataCreators.deleteUserDataFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}

export function* deactivateUserSaga(action) {
  try {
    yield put(userDataCreators.deactivateUserDataStart());

    const { id, navigate } = action.payload;

    let url = `${apiEndPoints.deleteUser}${id}/deactivate/`;

    const response = yield call(ApiService.post, url, {});
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Successfully Deactivated', ToastPosition.TOP_CENTER, 'success1');
      yield put(userDataCreators.deactivateUserDataSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    yield put(userDataCreators.deactivateUserDataFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}

export function* activateUserSaga(action) {
  try {
    yield put(userDataCreators.activateUserDataStart());

    const { id } = action.payload;

    let url = `${apiEndPoints.deleteUser}${id}/activate/`;

    const response = yield call(ApiService.post, url, {});
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Successfully Activated', ToastPosition.TOP_CENTER, 'success1');
      yield put(userDataCreators.activateUserDataSuccess());
    } else {
      throw response;
    }
  } catch (error) {
    yield put(userDataCreators.activateUserDataFailure());
    let message;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          message = error.response.data.message;
          break;
        case 500:
          message = 'Internal Server Error';
          break;
        default:
          message = 'Unable to update. Try again later';
          break;
      }
    } else {
      message = `Network Error`;
    }
    ErrorToast(message, ToastPosition.TOP_CENTER, 'error1');
  }
}
