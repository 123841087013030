import { createReducer } from 'reduxsauce';
import { NotificationTypes } from './action';

const INITIAL_STATE = {
  loading: false,
  pushNotifications: [],
  totalPages: 0,
  listNotificationMessage: '',
  addNotificationMessage: '',
};

const loadNotificationListStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    totalPages: 0,
    listNotificationMessage: '',
    pushNotifications: [],
  };
};

const loadNotificationListSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    listNotificationMessage: 'Succeed',
    ...action.payload,
  };
};

const loadNotificationListFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    listNotificationMessage: 'Failed',
  };
};

const addNotificationStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
    addNotificationMessage: '',
  };
};

const addNotificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    addNotificationMessage: 'Succeed',
  };
};

const addNotificationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    addNotificationMessage: 'Failed',
  };
};

const dataReset = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    pushNotifications: [],
    totalPages: 0,
    listNotificationMessage: '',
    addNotificationMessage: '',
  };
};

const HANDLERS = {
  [NotificationTypes.LOAD_NOTIFICATION_LIST_START]: loadNotificationListStart,
  [NotificationTypes.LOAD_NOTIFICATION_LIST_SUCCESS]: loadNotificationListSuccess,
  [NotificationTypes.LOAD_NOTIFICATION_LIST_FAILURE]: loadNotificationListFailure,
  [NotificationTypes.ADD_NOTIFICATION_START]: addNotificationStart,
  [NotificationTypes.ADD_NOTIFICATION_SUCCESS]: addNotificationSuccess,
  [NotificationTypes.ADD_NOTIFICATION_FAILURE]: addNotificationFailure,
  [NotificationTypes.DATA_RESET]: dataReset,
};

export const notificationReducer = createReducer(INITIAL_STATE, HANDLERS);
