import { takeLatest } from 'redux-saga/effects';
import { Types } from './actions';
import { loadUserSaga } from '../pages/Users/store/saga';
import { loginSaga, logoutSaga } from '../pages/Auth/store/saga';
import {
  addUserSaga,
  deleteUserSaga,
  deactivateUserSaga,
  activateUserSaga,
} from '../pages/Users/store/saga';
import {
  fetchBioScopeListSaga,
  bioScopeDetailsSaga,
  addBioScopeSaga,
  editBioScopeSaga,
  approveRejectBioScopeSaga,
  bioscopeMovieSuggestionSaga,
} from '../pages/BioScope/store/saga';
import { loadNotificationListSaga, addNotificationSaga } from '../pages/Notification/store/saga';
import {
  fetchCompanyListSaga,
  companyDetailsSaga,
  addCompanySaga,
  editCompanySaga,
} from '../pages/Company/store/saga';
import {
  addCouponInstanceSaga,
  couponInstanceDetailsSaga,
  editCouponInstanceSaga,
  fetchCouponInstanceListSaga,
  uploadCouponsForUsersSaga,
} from '../pages/CouponInstance/store/saga';

export function* watchAuth() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.LOGOUT, logoutSaga);
  yield takeLatest(Types.LOAD_USER_DATA, loadUserSaga);
  yield takeLatest(Types.ADD_USER_DATA, addUserSaga);
  yield takeLatest(Types.FETCH_BIO_SCOPE_LIST, fetchBioScopeListSaga);
  yield takeLatest(Types.BIO_SCOPE_DETAILS, bioScopeDetailsSaga);
  yield takeLatest(Types.ADD_BIO_SCOPE, addBioScopeSaga);
  yield takeLatest(Types.EDIT_BIO_SCOPE, editBioScopeSaga);
  yield takeLatest(Types.APPROVE_REJECT_BIO_SCOPE, approveRejectBioScopeSaga);
  yield takeLatest(Types.DELETE_USER_DATA, deleteUserSaga);
  yield takeLatest(Types.DEACTIVATE, deactivateUserSaga);
  yield takeLatest(Types.ACTIVATE, activateUserSaga);
  yield takeLatest(Types.BIOSCOPE_MOVIE_SUGGESTION, bioscopeMovieSuggestionSaga);
  yield takeLatest(Types.LOAD_NOTIFICATION_LIST, loadNotificationListSaga);
  yield takeLatest(Types.ADD_NOTIFICATION, addNotificationSaga);
  yield takeLatest(Types.FETCH_COMPANY_LIST, fetchCompanyListSaga);
  yield takeLatest(Types.COMPANY_DETAILS, companyDetailsSaga);
  yield takeLatest(Types.ADD_COMPANY, addCompanySaga);
  yield takeLatest(Types.EDIT_COMPANY, editCompanySaga);
  yield takeLatest(Types.FETCH_COUPON_INSTANCE_LIST, fetchCouponInstanceListSaga);
  yield takeLatest(Types.COUPON_INSTANCE_DETAILS, couponInstanceDetailsSaga);
  yield takeLatest(Types.ADD_COUPON_INSTANCE, addCouponInstanceSaga);
  yield takeLatest(Types.EDIT_COUPON_INSTANCE, editCouponInstanceSaga);
  yield takeLatest(Types.UPLOAD_COUPONS_FOR_USERS, uploadCouponsForUsersSaga);
}
