import { put, call } from 'redux-saga/effects';
import moment from 'moment';
import { CouponInstanceCreators } from './action';
import { ApiService, ApiServiceMultipart } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { ErrorToast, SuccessToast } from '../../../services/toast/toast';
import { ToastPosition } from '../../../constants/baseStyles';

export function* fetchCouponInstanceListSaga(action) {
  const { pageNumber, page_size, companyId } = action.payload;
  try {
    yield put(CouponInstanceCreators.fetchCouponInstanceListStart());
    let response;
    response = yield call(ApiService.get, apiEndPoints.listCouponsInstance, {
      params: {
        page: pageNumber,
        page_size: page_size,
        organization_id: companyId,
        active_only: false,
        available_only: false,
        user_only: false,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const { total_pages, data } = response.data;
      const payload = {
        total_pages,
        data,
      };
      yield put(CouponInstanceCreators.fetchCouponInstanceListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(CouponInstanceCreators.fetchCouponInstanceListFailure({ errorMessage: 'Failed' }));
  }
}

export function* couponInstanceDetailsSaga(action) {
  try {
    const {
      payload: { couponInstanceId },
    } = action;
    yield put(CouponInstanceCreators.couponInstanceDetailsStart());
    const response = yield call(
      ApiService.get,
      `${apiEndPoints.detailsCouponsInstance}${couponInstanceId}/`
    );
    if (response.status >= 200 && response.status < 300) {
      const {
        data: { data },
      } = response;
      if (data) {
        const couponInstanceDetails = {
          organizationId: data.organization_id,
          totalCoupons: data.total_coupons,
          availableCoupons: data.available_coupons,
          couponsPerUser: data.coupons_per_user,
          cost: data.cost,
          redeemByUsing: data.redeem_by_using,
          bannerImage: {
            id: '1',
            label: `Banner Image`,
            file: null,
            imageURL: data.banner_image,
            name: 'Banner Image',
            index: 0,
          },
          thumbnail: {
            id: '1',
            label: `Coupon Thumbnail`,
            file: null,
            imageURL: data.thumbnail,
            name: 'Thumbnail',
            index: 0,
          },
          title: data.title,
          description: data.description,
          howToUse: data.how_to_use?.how_to_use?.join('\n'),
          redeemLink: data.redeem_link,
          termsAndConditionsBasic:
            data.terms_and_conditions_basic?.terms_and_conditions_basic?.join('\n'),
          termsAndConditionsLink: data.terms_and_conditions_link,
          startDate: moment(data.start_date).format(moment.HTML5_FMT.DATETIME_LOCAL),
          endDate: moment(data.end_date).format(moment.HTML5_FMT.DATETIME_LOCAL),
          shortDescription: data.short_description,
          couponLogo: {
            id: '1',
            label: `Coupon Icon`,
            file: null,
            imageURL: data.coupon_logo,
            name: 'Coupon Icon',
            index: 0,
          },
          about: data.about,
          styles: {
            cardTitleColor: data.styles.color || '',
          },
          couponExpire: data.expiring_in_days,
        };
        yield put(CouponInstanceCreators.couponInstanceDetailsSuccess({ couponInstanceDetails }));
      } else {
        throw response;
      }
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error);
    ErrorToast(
      'Failed to get Coupon Instance data',
      ToastPosition.TOP_CENTER,
      'err-to-get-CouponInstance'
    );
    yield put(CouponInstanceCreators.couponInstanceDetailsFailure({ errorMessage: 'Failed' }));
  }
}

export function* addCouponInstanceSaga(action) {
  try {
    yield put(CouponInstanceCreators.addCouponInstanceStart());
    const {
      payload: { fileUpload },
    } = action;

    const response = yield call(
      ApiServiceMultipart.post,
      apiEndPoints.addCouponsInstance,
      fileUpload
    );
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Coupon Instance Added', ToastPosition.TOP_CENTER, 'success-to-add');
      yield put(CouponInstanceCreators.addCouponInstanceSuccess({}));
    } else {
      throw response;
    }
  } catch (err) {
    console.log(err);
    ErrorToast('Failed to add Coupon Instance', ToastPosition.TOP_CENTER, 'err-to-add');
    yield put(CouponInstanceCreators.addCouponInstanceFailure({ errorMessage: 'Failed' }));
  }
}

export function* editCouponInstanceSaga(action) {
  try {
    yield put(CouponInstanceCreators.editCouponInstanceStart());
    const {
      payload: { fileUpload, couponInstanceId },
    } = action;

    const response = yield call(
      ApiServiceMultipart.post,
      `${apiEndPoints.updateCouponsInstance}${couponInstanceId}/update/`,
      fileUpload
    );
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Coupon Instance edited', ToastPosition.TOP_CENTER, 'success-to-edit');
      yield put(CouponInstanceCreators.editCouponInstanceSuccess({}));
      yield put(CouponInstanceCreators.couponInstanceDetails({ couponInstanceId }));
    } else {
      throw response;
    }
  } catch (err) {
    ErrorToast('Failed to edit Coupon Instance', ToastPosition.TOP_CENTER, 'err-to-edit');
    yield put(CouponInstanceCreators.editCouponInstanceFailure({ errorMessage: 'Failed' }));
  }
}

export function* uploadCouponsForUsersSaga(action) {
  try {
    yield put(CouponInstanceCreators.uploadCouponsForUsersStart());
    const {
      payload: { fileUpload },
    } = action;

    const response = yield call(ApiServiceMultipart.post, apiEndPoints.addCoupons, fileUpload);
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Coupons Added', ToastPosition.TOP_CENTER, 'success-to-add');
      yield put(CouponInstanceCreators.uploadCouponsForUsersSuccess({}));
    } else {
      throw response;
    }
  } catch (err) {
    console.log(err);
    ErrorToast('Failed to add Coupons', ToastPosition.TOP_CENTER, 'err-to-add');
    yield put(CouponInstanceCreators.uploadCouponsForUsersFailure({ errorMessage: 'Failed' }));
  }
}
