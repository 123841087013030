import { put, call } from 'redux-saga/effects';
import moment from 'moment';
import { BioScopeCreators } from './action';
import { ApiService } from '../../../services/axios/apiService';
import { apiEndPoints } from '../../../services/axios/endPoint';
import { ErrorToast, SuccessToast } from '../../../services/toast/toast';
import { ToastPosition } from '../../../constants/baseStyles';

export function* fetchBioScopeListSaga(action) {
  const { pageNumber, page_size } = action.payload;
  try {
    yield put(BioScopeCreators.fetchBioScopeListStart());
    let response;
    response = yield call(ApiService.get, apiEndPoints.bioScopeList, {
      params: {
        page: pageNumber,
        page_size: page_size,
      },
    });
    const payload = response.data.data;
    if (response.status >= 200 && response.status < 300) {
      yield put(BioScopeCreators.fetchBioScopeListSuccess(payload));
    } else {
      throw response;
    }
  } catch (error) {
    BioScopeCreators.fetchBioScopeListFailure({ errorMessage: 'Failed' });
  }
}

export function* bioScopeDetailsSaga(action) {
  try {
    const {
      payload: { bioScopeId },
    } = action;
    yield put(BioScopeCreators.bioScopeDetailsStart());
    const response = yield call(
      ApiService.get,
      `${apiEndPoints.bioScopeDetail}${bioScopeId}/read/`
    );
    if (response.status >= 200 && response.status < 300) {
      const {
        data: { data: bioscopes },
      } = response;
      if (bioscopes) {
        const bioScopeDetails = {
          files: [],
          startDate: moment(bioscopes.start_datetime).format(moment.HTML5_FMT.DATETIME_LOCAL),
          endDate: moment(bioscopes.end_datetime).format(moment.HTML5_FMT.DATETIME_LOCAL),
          rightAnswerEnglish: bioscopes.answer?.name,
          rightAnswerOtherLang: bioscopes.answer?.local_name,
        };
        bioscopes?.frames.forEach((bioscope, index) => {
          const file = {
            id: bioscope.id.toString(),
            label: `IMAGE ${index + 1}`,
            file: null,
            imageURL: bioscope.image,
            name: bioscope.name,
            index,
          };
          bioScopeDetails.files.push(file);
        });
        yield put(BioScopeCreators.bioScopeDetailsSuccess({ bioScopeDetails }));
      } else {
        throw response;
      }
    } else {
      throw response;
    }
  } catch (error) {
    ErrorToast('Failed to get bio scope data', ToastPosition.TOP_CENTER, 'err-to-get-bioscope');
    yield put(BioScopeCreators.bioScopeDetailsFailure({ errorMessage: 'Failed' }));
  }
}

export function* addBioScopeSaga(action) {
  try {
    yield put(BioScopeCreators.addBioScopeStart());
    const {
      payload: { fileUpload },
    } = action;

    const response = yield call(ApiService.post, apiEndPoints.addBioScope, fileUpload);
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Bio Scope Added', ToastPosition.TOP_CENTER, 'success-to-add');
      yield put(BioScopeCreators.addBioScopeSuccess({}));
    } else {
      throw response;
    }
  } catch (err) {
    ErrorToast('Failed to add bio scope', ToastPosition.TOP_CENTER, 'err-to-add');
    yield put(BioScopeCreators.addBioScopeFailure({ errorMessage: 'Failed' }));
  }
}

export function* editBioScopeSaga(action) {
  try {
    yield put(BioScopeCreators.editBioScopeStart());
    const {
      payload: { fileUpload, bioScopeId },
    } = action;

    const response = yield call(
      ApiService.post,
      `${apiEndPoints.bioScopeDetail}${bioScopeId}/update/`,
      fileUpload
    );
    if (response.status >= 200 && response.status < 300) {
      SuccessToast('Bio Scope edited', ToastPosition.TOP_CENTER, 'success-to-edit');
      yield put(BioScopeCreators.editBioScopeSuccess({}));
      yield put(BioScopeCreators.bioScopeDetails({ bioScopeId }));
    } else {
      throw response;
    }
  } catch (err) {
    ErrorToast('Failed to edit bio scope', ToastPosition.TOP_CENTER, 'err-to-edit');
    yield put(BioScopeCreators.editBioScopeFailure({ errorMessage: 'Failed' }));
  }
}

export function* approveRejectBioScopeSaga(action) {
  try {
    yield put(BioScopeCreators.approveRejectBioScopeStart());
    const {
      payload: { requestType, bioScopeId },
    } = action;

    let url = `${apiEndPoints.bioScopeDetail}${bioScopeId}/approve/`;
    if (requestType === 'REJECT') {
      url = `${apiEndPoints.bioScopeDetail}${bioScopeId}/reject/`;
    }
    const response = yield call(ApiService.post, url, {});
    if (response.status >= 200 && response.status < 300) {
      SuccessToast(
        `Bio Scope ${requestType}`,
        ToastPosition.TOP_CENTER,
        'success-to-approveReject'
      );
      yield put(BioScopeCreators.approveRejectBioScopeSuccess({}));
    } else {
      throw response;
    }
  } catch (err) {
    const {
      payload: { requestType },
    } = action;
    if (err.response.data.message === '') {
      ErrorToast(
        `Failed to ${requestType} bio scope`,
        ToastPosition.TOP_CENTER,
        'err-to-approveReject'
      );
    } else {
      ErrorToast(err.response.data.message, ToastPosition.TOP_CENTER, 'err-to-approveReject');
    }

    yield put(BioScopeCreators.approveRejectBioScopeFailure({ errorMessage: 'Failed' }));
  }
}

// Movie suggestion saga
export function* bioscopeMovieSuggestionSaga(action) {
  const { name } = action.payload;
  try {
    yield put(BioScopeCreators.bioscopeMovieSuggestionStart());
    const response = yield call(ApiService.get, apiEndPoints.movieSuggestions, {
      params: { search: name },
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response?.data;
      yield put(BioScopeCreators.bioscopeMovieSuggestionSuccess(data));
    } else {
      throw response;
    }
  } catch (error) {
    console.log('error in MovieSuggestionSaga', error);
  }
}
