import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // GET CouponInstance List actions
  fetchCouponInstanceList: ['payload'],
  fetchCouponInstanceListStart: null,
  fetchCouponInstanceListSuccess: ['payload'],
  fetchCouponInstanceListFailure: ['payload'],
  //  CouponInstance Details actions
  couponInstanceDetails: ['payload'],
  couponInstanceDetailsStart: null,
  couponInstanceDetailsSuccess: ['payload'],
  couponInstanceDetailsFailure: ['payload'],
  // Add CouponInstance  actions
  addCouponInstance: ['payload'],
  addCouponInstanceStart: null,
  addCouponInstanceSuccess: ['payload'],
  addCouponInstanceFailure: ['payload'],
  // Edit CouponInstance  actions
  editCouponInstance: ['payload'],
  editCouponInstanceStart: null,
  editCouponInstanceSuccess: ['payload'],
  editCouponInstanceFailure: ['payload'],
  //
  uploadCouponsForUsers: ['payload'],
  uploadCouponsForUsersStart: null,
  uploadCouponsForUsersSuccess: ['payload'],
  uploadCouponsForUsersFailure: ['payload'],
});

export { Types as CouponInstanceTypes, Creators as CouponInstanceCreators };
