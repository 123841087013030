import { IS_DEV } from '../../constants/constant';

export const rootConfig = {
  apiRoot: IS_DEV ? 'https://devapi.odo.world/api/v1' : 'https://api.odo.world/api/v1',
};

export const apiEndPoints = {
  login: `${rootConfig.apiRoot}/admin/login/`,
  userList: `${rootConfig.apiRoot}/admin/user/list/`,
  deleteUser: `${rootConfig.apiRoot}/admin/user/`,
  addUser: `${rootConfig.apiRoot}/admin/user/add/`,
  bioScopeList: `${rootConfig.apiRoot}/bioscope/list/`,
  bioScopeDetail: `${rootConfig.apiRoot}/bioscope/`,
  bioScopeCampaign: `${rootConfig.apiRoot}/bioscope/campaign/`,
  addBioScope: `${rootConfig.apiRoot}/bioscope/add/`,
  movieSuggestions: `${rootConfig.apiRoot}/bioscope/movie/list/`,
  notificationList: `${rootConfig.apiRoot}/notification/push-notifs/`,
  addNotification: `${rootConfig.apiRoot}/notification/push-notifs/add/`,
  addOrginasation: `${rootConfig.apiRoot}/rewards/organization/add/`,
  updateOrginasation: `${rootConfig.apiRoot}/rewards/organization/`,
  detailsOrginasation: `${rootConfig.apiRoot}/rewards/organization/`,
  listOrginasation: `${rootConfig.apiRoot}/rewards/organization/list/`,
  addCouponsInstance: `${rootConfig.apiRoot}/rewards/coupon/add/`,
  updateCouponsInstance: `${rootConfig.apiRoot}/rewards/coupon/`,
  detailsCouponsInstance: `${rootConfig.apiRoot}/rewards/coupon/`,
  listCouponsInstance: `${rootConfig.apiRoot}/rewards/coupon/list/`,
  addCoupons: `${rootConfig.apiRoot}/rewards/coupon/user/add/`,
};
