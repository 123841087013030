import { createReducer } from 'reduxsauce';
import { CAMPAIGN_DETAILS_OBJ } from '../../../constants/constant';
import { CampaignsTypes } from './action';

const INITIAL_STATE = {
  campaignList: [],
  totalPage: 1,
  currentPage: 1,
  loadingCampaigList: false,
  loadingCampaignDetails: false,
  campaignDetails: CAMPAIGN_DETAILS_OBJ,
  loadingAddCampaign: null,
};

const fetchCampaignListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCampaigList: true,
});

const fetchCampaignListSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { totalPage, currentPage, campaignList },
  } = action;
  return {
    ...state,
    loadingCampaigList: false,
    totalPage,
    currentPage,
    campaignList,
  };
};

const fetchCampaignListFaliure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingCampaigList: false,
  };
};

const getCampaignDetailsStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingCampaignDetails: true,
});

const getCampaignDetailsSuccess = (state = INITIAL_STATE, action) => {
  const {
    payload: { campaignDetails },
  } = action;
  return {
    ...state,
    loadingCampaignDetails: false,
    campaignDetails,
  };
};

const getCampaignDetailsFaliure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingCampaignDetails: false,
  };
};

const addCampaignStart = (state = INITIAL_STATE, action) => ({
  ...state,
  loadingAddCampaign: true,
});

const addCampaignSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddCampaign: false,
    campaignDetails: CAMPAIGN_DETAILS_OBJ,
  };
};

const addCampaignFaliure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loadingAddCampaign: false,
  };
};

const HANDLERS = {
  [CampaignsTypes.FETCH_CAMPAIGN_LIST_START]: fetchCampaignListStart,
  [CampaignsTypes.FETCH_CAMPAIGN_LIST_SUCCESS]: fetchCampaignListSuccess,
  [CampaignsTypes.FETCH_CAMPAIGN_LIST_FALIURE]: fetchCampaignListFaliure,

  [CampaignsTypes.GET_CAMPAIGN_DETAILS_START]: getCampaignDetailsStart,
  [CampaignsTypes.GET_CAMPAIGN_DETAILS_SUCCESS]: getCampaignDetailsSuccess,
  [CampaignsTypes.GET_CAMPAIGN_DETAILS_FALIURE]: getCampaignDetailsFaliure,

  [CampaignsTypes.ADD_CAMPAIGN_START]: addCampaignStart,
  [CampaignsTypes.ADD_CAMPAIGN_SUCCESS]: addCampaignSuccess,
  [CampaignsTypes.ADD_CAMPAIGN_FALIURE]: addCampaignFaliure,
};

export const campaignReducer = createReducer(INITIAL_STATE, HANDLERS);
