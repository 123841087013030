import {
  Page,
  DashboardContainer,
  CardsContainer,
  CardContainerLink,
  CardIconContainer,
  CardIcon,
  CardTitleContainer,
  CardTitleText,
} from './Dashboard.Styles';
import { cookie } from '../../services/cookies/cookieServices';

let CARDS = [
  {
    title: 'Users',
    link: '/users',
    allowedUsers: ['SUPER_ADMIN', 'Super Admin'],
  },
  {
    title: 'Bio Scope',
    link: '/bio-scope',
    allowedUsers: 'ALL',
  },
  {
    title: 'Bio Scope Approval',
    link: '/bio-scope/approval',
    allowedUsers: ['SUPER_ADMIN', 'Super Admin'],
  },
  // {
  //   title: 'Bio Scope Campaign',
  //   link: '/bio-scope-campaign',
  //   allowedUsers: 'ALL',
  // },
  {
    title: 'Add Movies List',
    link: '/movies/add',
    allowedUsers: 'ALL',
  },
  {
    title: 'Notification',
    link: '/notification',
    allowedUsers: 'ALL',
  },
  // {
  //   title: 'Coupons',
  //   link: '/coupons',
  //   allowedUsers: 'ALL',
  // },
  {
    title: 'Organisations',
    link: '/company',
    allowedUsers: 'ALL',
  },
];

const Dashboard = () => {
  const userDetails = cookie.get('userDetails');

  if (userDetails.userType !== 'Super Admin') {
    CARDS = [
      {
        title: 'Bio Scope',
        link: '/bio-scope',
        allowedUsers: 'ALL',
      },
      {
        title: 'Add Movies List',
        link: '/movies/add',
        allowedUsers: 'ALL',
      },
      // {
      //   title: 'Bio Scope Campaign',
      //   link: '/bio-scope-campaign',
      //   allowedUsers: 'ALL',
      // },
    ];
  }

  return (
    <Page>
      <DashboardContainer>
        <CardsContainer>
          {CARDS.map((card, index) => (
            <CardContainerLink key={index} to={card.link}>
              <CardIconContainer>
                <CardIcon></CardIcon>
              </CardIconContainer>
              <CardTitleContainer>
                <CardTitleText>{card.title}</CardTitleText>
              </CardTitleContainer>
            </CardContainerLink>
          ))}
        </CardsContainer>
      </DashboardContainer>
    </Page>
  );
};

export default Dashboard;
