import { PlusIcon } from '../../constants/globalStyles.Styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination/Pagination.View';
import { userDataCreators } from './store/action';
import {
  UsersContainer,
  Page,
  UserTop,
  UserButton,
  UserHeading,
  UserTableContainer,
  UserTable,
  UserTableHeading,
  UserTableRow,
  UserTableData,
  UserTableHead,
  UserTableBody,
  UserTableLoadingData,
  StyledModal,
  Content,
  Button,
  ButtonContainer,
  MenuContainer,
  SpecificationModal,
  SpecificationData,
  LeftIcon,
} from './Users.Styles';
import { useEffect, useState } from 'react';

const Users = () => {
  //State mangement
  const [isOpen, setIsOpen] = useState(false);
  const [delOrDea, setdelOrDea] = useState('');
  const [menuId, setmenuId] = useState(0);
  const [menuVisibility, setmenuVisibility] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  //redux store management
  const user = useSelector((state) => state.userData);
  const userData = user.userData;
  const total_pages = user.total_pages;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //navigation for adding new user
  const openAddUserPage = () => {
    navigate('/users/add');
  };

  //After deleted the user , it will reload the userdata again
  useEffect(() => {
    if (
      user.deleteUserMessage === 'Succeed' ||
      user.deactivateUserMessage === 'Succeed' ||
      user.activateUserMessage === 'Succeed'
    ) {
      dispatch(userDataCreators.loadUserData({ pageNumber: 1 }));
      dispatch(userDataCreators.dataReset());
      setIsOpen(false);
    }
  }, [user.deleteUserMessage, user.deactivateUserMessage, user.activateUserMessage]);

  //In Initial loading it loads the user data
  useEffect(() => {
    dispatch(userDataCreators.loadUserData({ pageNumber: 1 }));
  }, []);

  //for deleting the user
  const userDelete = () => {
    dispatch(userDataCreators.deleteUserData({ id: menuId, navigate }));
  };

  //for deactivating the user
  const userDeactivate = () => {
    dispatch(userDataCreators.deactivate({ id: menuId, navigate }));
  };

  //for activating the user
  const userActivate = () => {
    dispatch(userDataCreators.activate({ id: menuId, navigate }));
  };

  //if superadmin click the menu icon , it will set that user's id in state management and it will show the menu information
  const menuClicked = (item) => {
    setmenuId(item.id);
    if (!menuVisibility || item.id === menuId) {
      setmenuVisibility((prevstate) => !prevstate);
    }
  };

  //confirmation modal for deleting and deactivating the user
  const toggleModal = (delOrDea = '') => {
    if (delOrDea === 'Delete') {
      setdelOrDea('Delete');
    } else if (delOrDea === 'Deactivate') {
      setdelOrDea('Deactivate');
    } else if (delOrDea === 'Activate') {
      setdelOrDea('Activate');
    } else {
      setdelOrDea('');
    }
    setIsOpen(!isOpen);
    if (!isOpen) {
      setmenuVisibility(false);
    }
  };

  //main button for deleting and deactivating the user
  const yesClickHandler = () => {
    if (delOrDea === 'Delete') {
      userDelete();
    } else if (delOrDea === 'Deactivate') {
      userDeactivate();
    } else {
      userActivate();
    }
  };

  return (
    <Page>
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <Content>
          Do you want continue to{' '}
          {delOrDea === 'Delete' ? `delete` : delOrDea === 'Deactivate' ? `deactivate` : `Activate`}{' '}
          this user?
        </Content>
        <ButtonContainer>
          <Button onClick={() => yesClickHandler()}>Yes</Button>
          <Button onClick={() => toggleModal()}>No</Button>
        </ButtonContainer>
      </StyledModal>
      <UsersContainer>
        <UserTop>
          <UserHeading>Users</UserHeading>
          <UserButton onClick={openAddUserPage}>
            <PlusIcon></PlusIcon>
            New User
          </UserButton>
        </UserTop>
        <UserTableContainer>
          <UserTable>
            <UserTableHead>
              <UserTableRow>
                <UserTableHeading>Email</UserTableHeading>
                <UserTableHeading>NAME</UserTableHeading>
                <UserTableHeading>PERMISSION</UserTableHeading>
                <UserTableHeading>IS-ACTIVE</UserTableHeading>
                <UserTableHeading>CONTACT</UserTableHeading>
                <UserTableHeading></UserTableHeading>
              </UserTableRow>
            </UserTableHead>
            {user.loading ? (
              <UserTableBody>
                <UserTableRow>
                  <UserTableLoadingData>Loading...</UserTableLoadingData>
                </UserTableRow>
              </UserTableBody>
            ) : (
              <UserTableBody>
                {userData.map((item, id) => {
                  return (
                    <UserTableRow key={id.toString()}>
                      <UserTableData>{item.email}</UserTableData>
                      <UserTableData>{item.first_name}</UserTableData>
                      <UserTableData>{item.user_type}</UserTableData>
                      <UserTableData>{item.is_active ? `Active` : `Inactive`}</UserTableData>
                      <UserTableData>{item.phone_number}</UserTableData>

                      <UserTableData>
                        <MenuContainer onClick={() => menuClicked(item)} />
                        {menuVisibility && item.id === menuId && (
                          <>
                            <SpecificationModal>
                              <SpecificationData onClick={() => toggleModal('Delete')}>
                                Delete
                              </SpecificationData>
                              {item.is_active ? (
                                <SpecificationData onClick={() => toggleModal('Deactivate')}>
                                  Deactivate
                                </SpecificationData>
                              ) : (
                                <SpecificationData onClick={() => toggleModal('Activate')}>
                                  Activate
                                </SpecificationData>
                              )}
                            </SpecificationModal>

                            <LeftIcon />
                          </>
                        )}
                      </UserTableData>
                    </UserTableRow>
                  );
                })}
              </UserTableBody>
            )}
          </UserTable>
        </UserTableContainer>
        <Pagination total_pages={total_pages} pagesFor={'users'} page_size={pageSize}></Pagination>
      </UsersContainer>
    </Page>
  );
};

export default Users;
