import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { CouponInstanceCreators } from './store/action';
import { useMatch, useNavigate } from 'react-router-dom';
import CommonTable from '../../components/Table/Table.View';
import { PAGES } from '../../constants/constant';

const tableHeader = [
  {
    title: 'Coupon Title',
    key: 'title',
  },
  {
    title: 'Coupon Description',
    key: 'short_description',
  },
  {
    title: 'Start Date',
    key: 'start_date',
    dataConvert: 'Date',
  },
  {
    title: 'End Date',
    key: 'end_date',
    dataConvert: 'Date',
  },
];

const CouponInstance = () => {
  const couponInstance = useSelector((state) => state.couponInstance);
  const loadingCouponInstanceList = couponInstance.loadingCouponInstanceList;
  const totalPage = couponInstance.couponInstanceListTotalPage;
  const couponInstanceList = couponInstance.couponInstanceList;
  const couponInstanceURL = useMatch('company/details/:companyId/coupons');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumberCurrent, setPageNumberCurrent] = useState(1);

  useEffect(() => {
    //
    dispatch(
      CouponInstanceCreators.fetchCouponInstanceList({
        page_size: pageSize,
        pageNumber: pageNumberCurrent,
        companyId: couponInstanceURL.params.companyId,
      })
    );
  }, []);

  const visitDetails = (e) => {
    navigate(`details/${e}`);
  };

  const onPageChangeClick = ({ pageNumber, page_size }) => {
    //
    if (pageNumber !== pageNumberCurrent) {
      setPageNumberCurrent(pageNumber);
      dispatch(
        CouponInstanceCreators.fetchCouponInstanceList({
          page_size: pageSize,
          pageNumber: pageNumber,
        })
      );
    }
  };

  return (
    <CommonTable
      pageTitle={PAGES.COUPONINSTANCE.title}
      addButtonText={PAGES.COUPONINSTANCE.addButtonText}
      tableHeader={tableHeader}
      tableData={couponInstanceList}
      totalPage={totalPage}
      loading={loadingCouponInstanceList}
      pageSize={pageSize}
      onChangePageClick={onPageChangeClick}
      addButtonNavigation={PAGES.COUPONINSTANCE.addButtonNavigation}
      visitDetails={visitDetails}
    />
  );
};
export default CouponInstance;
